
import React, { FC } from "react";

export interface isLoad {
  loading?:boolean
}

const LoadingSpinner: FC<isLoad> = ({
  loading = false,
}) => {

    if (loading) {
        return <div>
            <div className="absolute right-1/2 bottom-1/2  transform translate-x-1/2 translate-y-1/2 ">
 
 <div className="border-t-transparent border-solid animate-spin  rounded-full border-teal-400 border-8 h-44 w-44">
     
 </div>
</div>
        </div>;
      }
    return(

       <div>
       
       </div>

    );

};
export default LoadingSpinner;