import React, { FC, ReactNode,useEffect,useState} from "react";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import HeaderFilter from "./HeaderFilter";
import StayCard from "components/StayCard/StayCard";
import { Link } from "react-router-dom";
import useFetch from "hooks/useFecth";
import { tabStays} from "state/cartSore";
import { useRecoilValue } from "recoil";
import axios from "axios";


// OTHER DEMO WILL PASS PROPS
const DEMO_DATA: StayDataType[] = DEMO_STAY_LISTINGS.filter((_, i) => i < 8);
const count=100;



//
export interface SectionGridFeaturePlacesProps {
  stayListings?: StayDataType[];
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  tabs?: string[];
}

const SectionGridFeaturePlaces: FC<SectionGridFeaturePlacesProps> = ({
  stayListings = DEMO_DATA,
  gridClass = "",
  heading = "Destaques da semana",
  subHeading = "Lugares recomendados pelo Barengue",
  headingIsCenter, //Home Filter
  tabs = ["Tudo","Bedfordview", "Sandton", "Cape Town", "Edenvale"],
}) => {
const {doc,loading,reFetch}=useFetch("/hotels")
const [stays,setStays]=useState<any>([]);
const city=useRecoilValue(tabStays);


useEffect(()=>{
    
  //console.log("this is "+mini);

  const fetchListings = async () => {
    try {
      
      const response = await axios.get('newPlaces', {
        params: {
          city: city,
        }
      });
      setStays(response.data);

    } catch (error) {
      console.error('Error fetching listings:', error);
    }};
  
    fetchListings()


  

  
},[city])

  const renderCard = (stay: StayDataType) => {
    return <StayCard key={stay.id} data={stay} />;
  };

    

  return (
    <div className="nc-SectionGridFeaturePlaces relative">
      <HeaderFilter
        tabActive={"Sandton"}
        subHeading={subHeading}
        tabs={tabs}
        heading={heading}
        onClickTab={() => {}}
      />
      <div
        className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ${gridClass}`}
      >
        {stays.map((stay:any) => renderCard(stay))}
         
      </div>
      
      <div className="flex mt-16 justify-center items-center">
        <ButtonPrimary loading>Ver mais...</ButtonPrimary>
      </div>
      
    </div>
  );
};

export default SectionGridFeaturePlaces;
