import { Tab } from "@headlessui/react";
import CarCard from "components/CarCard/CarCard";
import ExperiencesCard from "components/ExperiencesCard/ExperiencesCard";
import StayCard from "components/StayCard/StayCard";
import {
  DEMO_CAR_LISTINGS,
  DEMO_EXPERIENCES_LISTINGS,
  DEMO_STAY_LISTINGS,
} from "data/listings";
import React, { Fragment, useEffect, useState } from "react";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import CommonLayout from "./CommonLayout";
import { useNavigate } from "react-router-dom";
import { isLoggedIn } from "state/cartSore";
import { useRecoilValue } from "recoil";
import useFetch from "hooks/useFecth";
import useFetchAttractions from "hooks/useFecthAttractions";
import useFetchCars from "hooks/useFecthCars";
import useFetchStays from "hooks/useFecthStays";
import useFetch_Stays from "hooks/Data Hooks/stays";
import useFetch_Attractions from "hooks/Data Hooks/attractions";
import useFetch_Cars from "hooks/Data Hooks/cars";
import { users } from "state/cartSore";
import BookingCard from "components/StayCard/BookingCard";
import StayCardH from "components/StayCardH/StayCardH";
import PageLogin from "containers/PageLogin/PageLogin";

import { AnyTxtRecord } from "dns";
//import StayCard from "components/StayCard/StayCard";
import axios from "axios";

const AccountBookinglists = () => {

  const isLogged=useRecoilValue(isLoggedIn);
  const navigate=useNavigate()
  let [categories] = useState(["Stays", "Atracções", "Rental Car"]);
  const [search,setSearch]=useState("");
  const searchStays="/hotels?_id=653cc1c039145b294d440877";
  const[stay,setStay]=useState("653cc1c039145b294d440877")
  const profile=useRecoilValue(users)
  
  const {doc,loading,reFetch}=useFetch(`/bookings?email=${profile.email}&type=stays`);  
  const {docCars,loadingCars,reFetchCars}=useFetchCars(`/bookings?email=${profile.email}&type=cars`);
  const {docAttractions,loadingAttractions,reFetchAttractions}=useFetchAttractions(`/bookings?email=${profile.email}&type=attractions`);
  
  // copy of the functions to get data from each API(Stays,cars,attractions) to help the Filter
  //const {doc_Stays,loading_Stays,reFetch_Stays}=useFetch_Stays("/hotels?_id=653cc1c039145b294d440877") //(`/hotels?_id=${stay}`);
  const {doc_Cars,loading_Cars,reFetch_Cars}=useFetch_Cars("/rental");
  const {doc_Attractions,loading_Attractions,reFetch_Attractions}=useFetch_Attractions(`/attractions`);
  

  
  const {docStays,loadingStays,reFetchStays}=useFetchStays(`/hotels`);

  //const {docs,loadings,reFetchs}=useFetchStays("/bookings?email=florianoneve@icloud.com&type=stays");
    useEffect(()=>{
  /*  
isLogged !=true &&(
  navigate("/login")
)
  */
    },[])

  const renderSection1 = () => {

    return (

      //start of booking components
      
     


      <div className="space-y-6 sm:space-y-8">

{
        // user !== undefined && isAuthenticated && (
             
       isLogged ?
                 <p> NOT LOGGED IN</p>
            
         
         :<p></p>
     }
        <div>
          <h2 className="text-3xl font-semibold">Bookings</h2>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div>
          <Tab.Group>
            <Tab.List className="flex space-x-1 overflow-x-auto">
              {categories.map((item) => (
                <Tab key={item} as={Fragment}>
                  
                  {({ selected }) => (
                    <button
                      className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 capitalize rounded-full focus:outline-none ${
                        selected
                          ? "bg-secondary-900 text-secondary-50 "
                          : "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                      } `}
                    >
                      
                      {item}
                    </button>

                  )}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels>

              <Tab.Panel className="mt-8">
                
                <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                 
                  {/*DEMO_STAY_LISTINGS.filter((_, i) => i < 8).map((stay) => (
                    <StayCard key={stay.id} data={stay} />
                  ))*/}

                    
                    {
                       
                       doc.map((item:any) => {
                      
                       
                        const propertyName =item.place;
                        const newCart = docStays.filter((person:any) => person._id === item.place);
                        //const isItemInCart = docStays.find((cartItem:any) => cartItem._id === item.propertyName)
                        console.log("ola")
                        console.log(doc)
                        
                        return (<div className="flex-container card" key={item._id}>
                            <div className="content">

                         <span>
                         
                         </span>
                             { newCart.map((itemm:any) => {
                                  
                                      
                                        return (<div className="flex-container card" key={itemm._id}>
                                            <div className="content">
                                              
                                      {// <StayCard key={itemm._id} data={itemm} />
                                      }
                                            <BookingCard key={itemm._id} data={itemm} dates={item.checkout}/>
                                            
                                            </div>
                                        </div>)
                                    })
}

                            {/*

                          
                                        docStays.map((item:any) => {
                                        const test = item._id;

                                        return (<div className="flex-container card" key={item._id}>
                                            <div className="content">
                                                <p>{test}</p>
                                            </div>
                                        </div>)
                                    })
                                  */}

                            </div>
                        </div>)
                    })

                    }
                   
                          
                      {/*doc.map((stayl:any) => 

                      
                      <span>place: {stayl.place}

                
                      
                      {/*docStays.map((stayl:any) => 
                      

                      <StayCard key={stayl.i_d} data={stayl} />

                      }
 
                      </span>

                      
                      )*/}  
                      
                    
                </div>
                <div className="flex mt-11 justify-center items-center">
                  <ButtonSecondary>Show me more</ButtonSecondary>
                </div>
              </Tab.Panel>


              <Tab.Panel className="mt-8">
                <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                  {/*DEMO_EXPERIENCES_LISTINGS.filter((_, i) => i < 8).map(
                    (stay) => (
                      <ExperiencesCard key={stay.id} data={stay} />
                    )
                    )*/
                    }
                        {
                       
                       docAttractions.map((item:any) => {
                      
                       
                        const propertyName =item.place;
                        const newCart = doc_Attractions.filter((person:any) => person._id === item.place);
                        //const isItemInCart = docStays.find((cartItem:any) => cartItem._id === item.propertyName)
                       
                        
                        return (<div className="flex-container card" key={item._id}>
                            <div className="content">

                              
                              
                             { newCart.map((item:any) => {
                                        

                                        return (<div className="flex-container card" key={item._id}>
                                            <div className="content">
                                            <ExperiencesCard key={item.id} data={item} />
                                            </div>
                                        </div>)
                                    })
}

                            {/*

                          
                                        docStays.map((item:any) => {
                                        const test = item._id;

                                        return (<div className="flex-container card" key={item._id}>
                                            <div className="content">
                                                <p>{test}</p>
                                            </div>
                                        </div>)
                                    })
                                  */}

                            </div>
                        </div>)
                    })

                    }

                </div>
                <div className="flex mt-11 justify-center items-center">
                  <ButtonSecondary>Show me more</ButtonSecondary>
                </div>
              </Tab.Panel>
    
              

            
              <Tab.Panel className="mt-8">
                <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                  {/*DEMO_CAR_LISTINGS.filter((_, i) => i < 8).map((stay) => (
                    <CarCard key={stay.id} data={stay} />
                  ))*/}
                

                {
                       
                       docCars.map((item:any) => {
                      
                       
                        const propertyName =item.place;
                        const newCart = doc_Cars.filter((person:any) => person._id === item.place);
                        //const isItemInCart = docStays.find((cartItem:any) => cartItem._id === item.propertyName)
                       
                        
                        return (<div className="flex-container card" key={item._id}>
                            <div className="content">

                               
                              
                             { newCart.map((item:any) => {
                                        

                                        return (<div className="flex-container card" key={item._id}>
                                            <div className="content">
                                            <CarCard key={item._id} data={item} />
                                            </div>
                                        </div>)
                                    })
}

                            {/*

                          
                                        docStays.map((item:any) => {
                                        const test = item._id;

                                        return (<div className="flex-container card" key={item._id}>
                                            <div className="content">
                                                <p>{test}</p>
                                            </div>
                                        </div>)
                                    })
                                  */}

                            </div>
                        </div>)
                    })

                    }
</div>
                Rental car
                <div className="flex mt-11 justify-center items-center">
                  <ButtonSecondary>Show me more</ButtonSecondary>
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
        
      </div>
    );

    //end of booking components
  };

  return (
    <div>
       {
               // user !== undefined && isAuthenticated && (
                    
              isLogged  ?    
      <CommonLayout>{renderSection1()}</CommonLayout>
       
      :<PageLogin></PageLogin>
    }
    </div>
  );
};

export default AccountBookinglists;
