import React, { FC, useEffect, useState } from "react";
import { ArrowRightIcon, Squares2X2Icon } from "@heroicons/react/24/outline";
import { Amenities_demos, includes_demo, PHOTOS } from "./constant";
import CommentListing from "components/CommentListing/CommentListing";
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";
import StartRating from "components/StartRating/StartRating";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import LikeSaveBtns from "components/LikeSaveBtns";
import SectionDateRange from "../SectionDateRange";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonCircle from "shared/Button/ButtonCircle";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import DetailPagetLayout from "../Layout";
import RentalCarDatesRangeInput from "./RentalCarDatesRangeInput";
import { dateState,endDateState,dayState } from "state/cartSore";
import { useRecoilState ,useRecoilValue} from "recoil";
import { Link } from "react-router-dom";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import { differenceInDays } from 'date-fns';
import moment from "moment";
import DatePicker from "react-datepicker";
import RangeDates from "./RangeDates";
import useFetchAttractions from "hooks/useFecthAttractions";
import useFetchCars from "hooks/useFecthCars";
import { useParams } from "react-router-dom";
import axios from "axios";


const ListingCarDetail: FC<{}> = () => {
  // USE STATE

  const thisPathname = useLocation().pathname;
  const router = useNavigate();
  const params=useParams();
  const [thisState,setThisState]=useState(useLocation().state);
  const [Details,setDetails]=useState(!null);
  
  const [startDate, setStartDate]=useRecoilState(dateState);
  const  [endDate, setEndDate]=useRecoilState(endDateState);
  const [numberDays,setnumberDays]=useRecoilState(dayState);
  
  const searchAtt=`/rental?_id=${params.id}`;
  const searchCar=`/rental?_id=${params.id}`;
  const {docAttractions,loadingAttractions,reFetchAttractions}=useFetchAttractions(searchAtt);
  const {docCars,loadingCars,reFetchCars}=useFetchCars(searchCar);
  const [disc, setDisc] = useState<any[]>([]);
  const[totalP,setTotalP]=useState<any>();
  const [cars,setCars]=useState<any[]>([]);
  const[prices,Setprices] = useState<any[]>([]);

  const gallery=docCars.map((stays:any)=>{
    return( stays.photos)
  })

  

  const verifyDays=(days:any)=>{
    if(days==null || days==0)
    {
      setnumberDays(1);
    }
    return days;

  
  }

  const onChangeDate = (dates:any) => {
    const [start, end] = dates;

    
    setStartDate(start);
    
   

  
    
    setEndDate(end);
   
    if(end==null)
    {
      const numberdays=calculateDaysLeft(start,start);
      setnumberDays(numberdays+1);
    }
    if(end!=null)
    {
      const numberdays=(calculateDaysLeft(start,end));
      setnumberDays(numberdays);
      verifyDays(numberdays);
    }
   
   
  };

  const calculateDaysLeft=(startDate:any,endDate:any) =>{
    if (!moment.isMoment(startDate)) startDate = moment(startDate);
    if (!moment.isMoment(endDate)) endDate = moment(endDate);

    return endDate.diff(startDate, "days");
  }
  const daysLeft = calculateDaysLeft(startDate, endDate);


  const handleOpenModalImageGallery = () => {
    router(`${thisPathname}/?modal=PHOTO_TOUR_SCROLLABLE`);
  };

  const price=docAttractions.map((stays:any)=>{
    return(stays.price[0])
  })

  

  const getvalues=()=>{
    cars.map((stays:any)=>{
     
      setDisc(stays.photos);
    })
  }

  useEffect(()=>{

    const fetchData = async () => {
      
      try {
        const res = await axios.get(`/rental?_id=${params.id}`);
        //console.log(res.data);
        setCars(res.data);
      } catch (err:any) {
        
        alert("No internet connection")
      }
      //setLoading(false);
    };
    fetchData();

    const getvalues=()=>{
      cars.map((stays:any)=>{
       
        setDisc(stays.photos);
      })
    }

    getvalues();

    //const data=docCars;
    //console.log(params.id);

    //getvalues();
   
  
   
   
   
  },[cars])
  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap !space-y-6">
        {/* 1 */}
        <div className="flex justify-between items-center">

        {
          docAttractions.map((stays:any)=>{
            return(  <Badge color="pink" name={stays.make} />)
          })
        }
          
          <LikeSaveBtns />
        </div>

        {/* 2 */}
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
        {
          docAttractions.map((stays:any)=>{
            return(  stays.desc)
          })
        }
        
        </h2>

        {/* 3 */}
        <div className="flex items-center space-x-4">
          <StartRating />
          <span>·</span>
          <span>
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1"> 
            {
          docAttractions.map((stays:any)=>{
            return(  stays.city)
          })
        }
              

            </span>
          </span>
        </div>

        {/* 4 */}
        <div className="flex items-center">
          <Avatar hasChecked sizeClass="h-10 w-10" radius="rounded-full" />
          <span className="ml-2.5 text-neutral-500 dark:text-neutral-400">
            Verified
            
          </span>
        </div>

        {/* 5 */}
        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

        {/* 6 */}
        <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
          <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
            <i className="las la-user-friends text-2xl"></i>
            <span className="">
            {
          docAttractions.map((stays:any)=>{
            return(  stays.seats)
          })
        }
              </span>
          </div>
          <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
            <i className="las la-dharmachakra text-2xl"></i>
            <span className=""> Auto gearbox/ Manual</span>
          </div>
          <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
            <i className="las la-suitcase text-2xl"></i>
            <span className=""> 2 bags</span>
          </div>
        </div>
      </div>
    );
  };

  //
  const renderSectionTienIch = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">
            Vehicle parameters & utilities{" "}
          </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Questions are at the heart of making things great.
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-6 gap-x-10 text-sm text-neutral-700 dark:text-neutral-300 ">
          {/* TIEN ICH 1 */}
          {Amenities_demos.map((item, index) => (
            <div key={index} className="flex items-center space-x-4 ">
              <div className="w-10 flex-shrink-0">
                <img src={item.icon} alt="" />
              </div>
              <span>
              {
          docAttractions.map((stays:any)=>{
            return(  stays.name)
          })
        }
                </span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">
        {
          docAttractions.map((stays:any)=>{
            return(  stays.desc)
          })
        }
          </h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
          <p>
            Until the all-new TUCSON hits the dealer showrooms you can check it
            out in our Showroom Walkaround video. Watch the video and join our
            product specialist as he gives you an up-close look of our latest
            SUV
            <br />
            <br />
            Questions are at the heart of making things great. Watch our
            celebrity-filled TV ad and you’ll see that when we say “everything,”
            we mean everything.
          </p>
        </div>
      </div>
    );
  };

  const renderSection3 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Include </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Included in the price
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
          {includes_demo
            .filter((_, i) => i < 12)
            .map((item) => (
              <div key={item.name} className="flex items-center space-x-3">
                <i className="las la-check-circle text-2xl"></i>
                <span>{item.name}</span>
              </div>
            ))}
        </div>
      </div>
    );
  };

  const renderSection5 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Car Owner</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* host */}
        <div className="flex items-center space-x-4">
          <Avatar
            hasChecked
            hasCheckedClass="w-4 h-4 -top-0.5 right-0.5"
            sizeClass="h-14 w-14"
            radius="rounded-full"
          />
          <div>
            <a className="block text-xl font-medium" href="##">
              Kevin Francis
            </a>
            <div className="mt-1.5 flex items-center text-sm text-neutral-500 dark:text-neutral-400">
              <StartRating />
              <span className="mx-2">·</span>
              <span> 12 places</span>
            </div>
          </div>
        </div>

        {/* desc */}
        <span className="block text-neutral-6000 dark:text-neutral-300">
          Providing lake views, The Symphony 9 Tam Coc in Ninh Binh provides
          accommodation, an outdoor swimming pool, a bar, a shared lounge, a
          garden and barbecue facilities...
        </span>

        {/* info */}
        <div className="block text-neutral-500 dark:text-neutral-400 space-y-2.5">
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <span>Joined in March 2016</span>
          </div>
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
              />
            </svg>
            <span>Response rate - 100%</span>
          </div>
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>

            <span>Fast response - within a few hours</span>
          </div>
        </div>

        {/* == */}
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <ButtonSecondary href="/author">See host profile</ButtonSecondary>
        </div>
      </div>
    );
  };

  const renderSection6 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Reviews (23 reviews)</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* Content */}
        <div className="space-y-5">
          <FiveStartIconForRate iconClass="w-6 h-6" className="space-x-0.5" />
          <div className="relative">
            <Input
              fontClass=""
              sizeClass="h-16 px-4 py-3"
              rounded="rounded-3xl"
              placeholder="Share your thoughts ..."
            />
            <ButtonCircle
              className="absolute right-2 top-1/2 transform -translate-y-1/2"
              size=" w-12 h-12 "
            >
              <ArrowRightIcon className="w-5 h-5" />
            </ButtonCircle>
          </div>
        </div>

        {/* comment */}
        <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <div className="pt-8">
            <ButtonSecondary>View more 20 reviews</ButtonSecondary>
          </div>
        </div>
      </div>
    );
  };

  const renderSection7 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Location</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            San Diego, CA, United States of America (SAN-San Diego Intl.)
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* MAP */}
        <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3 ring-1 ring-black/10 rounded-xl z-0">
          <div className="rounded-xl overflow-hidden z-0">
            <iframe
              title="x"
              width="100%"
              height="100%"
              loading="lazy"
              allowFullScreen
              referrerPolicy="no-referrer-when-downgrade"
              src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAGVJfZMAKYfZ71nzL_v5i3LjTTWnCYwTY&q=Eiffel+Tower,Paris+France"
            ></iframe>
          </div>
        </div>
      </div>
    );
  };

  const renderSection8 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Things to know</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Cancellation policy</h4>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            Lock in this fantastic price today, cancel free of charge anytime.
            Reserve now and pay at pick-up.
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Special Note</h4>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            We asked ourselves, “How can we make the dash not only look better,
            but also give the driver a better look outside?” The unexpected
            answer is having no hood above the available 10.25-inch digital
            instrument cluster...
          </span>
        </div>
      </div>
    );
  };


//Footsticker mobile
const MobileFooterSticky = () => {
    
  const [showModal, setShowModal] = React.useState(false);

  return (
    <div className="block lg:hidden fixed bottom-0 inset-x-0 py-2 sm:py-3 bg-white dark:bg-neutral-800 border-t border-neutral-200 dark:border-neutral-6000 z-40">
      <div className="container flex items-center justify-between">
        
      <>
      
      <div className="flex-grow text-left" onClick={() => setShowModal(true)}>
        
        <span className="block xl:text-lg font-semibold">

        <span>AOA {(price[0]*(numberDays))} / People</span>

        </span>

        <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
        {startDate?.toLocaleDateString("en-US", {
            month: "short",
            day: "2-digit",
          }) || "Add dates"}
          {endDate
            ? " - " +
              endDate?.toLocaleDateString("en-US", {
                month: "short",
                day: "2-digit",
              })
            : ""}
        </span>
      </div>


    {showModal ? (
      <>
        <div
          className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
        >
          <div className="relative w-auto my-6 mx-auto max-w-3xl">
            {/*content*/}
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              {/*header*/}
              <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                <h3 className="text-3xl font-semibold">
                  Modal Title
                </h3>
                <button
                  className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={() => setShowModal(false)}
                >
                  <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                    ×
                  </span>
                </button>
              </div>
              {/*body*/}
              <div className="relative p-6 flex-auto">

              <div className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-neutral-800 p-8">
               <DatePicker
                 selected={startDate}
                 onChange={onChangeDate}
                 minDate={new Date()}
                 startDate={startDate}
                 endDate={endDate}
                 selectsRange
                 monthsShown={2}
                 showPopperArrow={false}
                 inline
                 renderCustomHeader={(p) => (
                   <DatePickerCustomHeaderTwoMonth {...p} />
                 )}
                 renderDayContents={(day, date) => (
                   <DatePickerCustomDay dayOfMonth={day} date={date} />
                 )}
               />
             </div>

              </div>
              {/*footer*/}
              <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
               
                <button
                  className="bg-teal-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => setShowModal(false)}
                >
                  Fechar
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </>
    ) : null}
  </>

  <Link to="/checkout-rental-car"
        state={thisState}
        >
        <ButtonPrimary >Reservar</ButtonPrimary>
        </Link>
           
        
      </div>
    </div>
  );
};

// end mobile sticky


  const renderSidebarPrice = () => {
    return (
      <div className="listingSectionSidebar__wrap shadow-xl">
        {/* PRICE */}
        <div className="flex justify-between">
          <span className="text-3xl font-semibold">
           AOA {
          docAttractions.map((stays:any)=>{
            return(stays.price)
          })
        }
            <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
              /Dia
            </span>
          </span>
          <StartRating />
        </div>

        {/* FORM */}
        <form className="border border-neutral-200 dark:border-neutral-700 rounded-2xl">
          <RangeDates />
        </form>

        {/* SUM */}
        <div className="flex flex-col space-y-4 ">
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>{
          docAttractions.map((stays:any)=>{
            return(stays.price)
          })
        } x {numberDays} days</span>
            <span>AOA {price[0]*numberDays}</span>
          </div>

          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Service Charges</span>
            <span> AOA 5000</span>
          </div>
        

          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>Total</span>
            <span>AOA {(price[0]*numberDays)+5000}</span>
          </div>
        </div>

        {/* SUBMIT */}
        <Link to="/checkout-rental-car"
        state={thisState}
        >
        <ButtonPrimary >Reservar</ButtonPrimary>
        </Link>
      </div>
    );
  };


  //rental car sidebar
  const renderSidebarDetail = () => {
    return (
      <div className="listingSection__wrap lg:shadow-xl">
        <span className="text-2xl font-semibold block">
          Pick up and drop off
        </span>
        <div className="mt-8 flex">
          <div className="flex-shrink-0 flex flex-col items-center py-2">
            <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
            <span className="block flex-grow border-l border-neutral-400 border-dashed my-1"></span>
            <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
          </div>
          <div className="ml-4 space-y-14 text-sm">
            <div className="flex flex-col space-y-2">
              <span className=" text-neutral-500 dark:text-neutral-400">
                Monday, August 12 · 10:00s
              </span>
              <span className=" font-semibold">
              {
          docAttractions.map((stays:any)=>{
            return(stays.address)
          })
        }
              </span>
            </div>
            <div className="flex flex-col space-y-2">
              <span className=" text-neutral-500 dark:text-neutral-400">
                Monday, August 16 · 10:00
              </span>
              <span className=" font-semibold">
              {
          docAttractions.map((stays:any)=>{
            return(stays.address)
          })
        }
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={` nc-ListingCarDetailPage `}>
      {/* SINGLE HEADER */}
      <header className="rounded-md sm:rounded-xl">
        <div className="relative grid grid-cols-4 gap-1 sm:gap-2">
          <div
            className="col-span-2 row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
            onClick={handleOpenModalImageGallery}
          >
            <img
              src={disc[0]}
              alt="0"
              className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
            />
            <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
          </div>

          {/*  
          <div
            className="col-span-1 row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
            onClick={handleOpenModalImageGallery}
          >
            <img
              className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
              src={data.photos}
              alt="1"
              sizes="400px"
            />
            <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
          </div>
          */}

          {/*  */}
          {disc.map((item:any, index:any) => (
            <div
              key={index}
              className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                index >= 2 ? "block" : ""
              }`}
            >
              <div className="aspect-w-4 aspect-h-3">
                <img
                  className="absolute inset-0 object-cover w-full h-full rounded-md sm:rounded-xl "
                  src={item || ""}
                  alt="photos"
                  sizes="400px"
                />
              </div>

              {/* OVERLAY */}
              <div
                className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                onClick={handleOpenModalImageGallery}
              />
            </div>
          ))}

          <div
            className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
            onClick={handleOpenModalImageGallery}
          >
            <Squares2X2Icon className="h-5 w-5" />

            <span className="ml-2 text-neutral-800 text-sm font-medium">
              Show all photos
            </span>
          </div>
        </div>
      </header>

      {/* MAIn */}
      <main className=" relative z-10 mt-11 flex flex-col lg:flex-row ">
        {/* CONTENT */}
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:pr-10 lg:space-y-10">
          {renderSection1()}
          <div className="block lg:hidden">
            {renderSidebarDetail()}</div>
          {//renderSectionTienIch()
          }
          {//renderSection2()
          }
          {//renderSection3()
          }
         

          {//  <SectionDateRange /> npm startrenderSection5()
          }
          {//renderSection6()
          }
          {//renderSection7()
          }
          {//renderSection8()
          }
        </div>

        {/* SIDEBAR */}
        <div className="block flex-grow mt-14 lg:mt-0">
          {renderSidebarDetail()}
          <div className="hidden lg:block mt-10 sticky top-28">
            {renderSidebarPrice()}
          </div>
        </div>
      </main>
      {MobileFooterSticky()}
    </div>
  );
};

export default function ListingCarDetailPage() {
  return (
    <DetailPagetLayout>
      <ListingCarDetail />
    </DetailPagetLayout>
  );
}
