import React, { Fragment, useState, FC,useEffect } from "react";
import { Popover, Transition } from "@headlessui/react";
import { CalendarIcon } from "@heroicons/react/24/outline";
import DatePicker from "react-datepicker";
import ClearDataButton from "components/HeroSearchForm/ClearDataButton";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import { differenceInDays } from 'date-fns';
import { dateState,endDateState,dayState,guestState,tickets } from "state/cartSore";
import { useRecoilState,useRecoilValue } from "recoil";
import { addDays } from 'date-fns';
import { DateRangePicker ,DateRange} from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Calendar } from "react-date-range";


import moment from "moment";



export interface RangeDates {
  className?: string;
}

const RangeDates: FC<RangeDates> = ({
  className = "flex-1",
}) => {
  const [startDates, setStartDate] = useRecoilState(dateState);

 
  const [endDates, setEndDate] =  useRecoilState(endDateState);

  const [numberDays,setnumberDays]=useRecoilState(dayState);
  

  const [state, setState] = useState([
    {
      startDate: startDates,
      endDate: endDates,
      key: 'selection'
    }
  ]);
  
 

const verifyDays=(days:any)=>{
  if(days==null || days==0)
  {
    setnumberDays(1);
  }
  return days;

}



  const onChangeDate = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);

    console.log(" teste");
    console.log(dates);
    
   
/*
    const newData={
      starts:JSON.stringify(start),
      ends:JSON.stringify(end)
    }
*/
  
    
    setEndDate(end);
   
    if(end==null)
    {
      const numberdays=calculateDaysLeft(start,start);
      setnumberDays(numberdays);
    }
    if(end!=null)
    {
      const numberdays=(calculateDaysLeft(start,end));
      setnumberDays(numberdays);
      verifyDays(numberdays);
    }
   
   
  };

  const calculateDaysLeft=(startDate:any,endDate:any) =>{
    if (!moment.isMoment(startDate)) startDate = moment(startDate);
    if (!moment.isMoment(endDate)) endDate = moment(endDate);

    return endDate.diff(startDate, "days");
  }
  const daysLeft = calculateDaysLeft(startDates, endDates);

  useEffect(()=>{
    console.log("date range :")
    console.log(startDates)
    console.log(endDates)
    
    
  },[state,startDates])

  const renderInput = () => {
    return (
      <>
        <div className="text-neutral-300 dark:text-neutral-400">
          <CalendarIcon className="w-5 h-5 lg:w-7 lg:h-7" />
        </div>
        <div className="flex-grow text-left">
          <span className="block xl:text-lg font-semibold">
            {startDates?.toLocaleDateString("en-US", {
              month: "short",
              day: "2-digit",
              year: "2-digit",
            }) || "Add dates"}
            {/*endDates
              ? " - " +
                endDates?.toLocaleDateString("en-US", {
                  month: "short",
                  day: "2-digit",
                })
              : "" */}
          </span>
          <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
            {"Selecionar Datas"}
            
          </span>
        </div>
      </>
    );
  };

  return (
    <Popover className={`StayDatesRangeInput z-10 relative flex `}>
      {({ open }) => (
        <>
          <Popover.Button
            className={`flex-1 flex relative p-3 items-center space-x-3 focus:outline-none ${
              open ? "shadow-lg" : ""
            }`}
          >
            {renderInput()}
            {startDates && open && (
              <ClearDataButton onClick={() => onChangeDate([new Date, new Date()])} />
            )}
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute left-auto xl:-right-5 right-0 z-10 mt-2 top-full w-screen max-w-sm px-4 sm:px-0 lg:max-w-1xl">
              <div className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-10 bg-white dark:bg-neutral-900 p-8">
              
              <Calendar onChange={(item:any )=> setStartDate(item)} date={startDates} />

              {/*<DateRange 
              editableDateInputs={true}
              onChange={(item :any)=> {
                setStartDate(item.selection.startDate)
                setEndDate(item.selection.endDate)
                setState([item.selection])
              }
              }
              moveRangeOnFirstSelection={false}
              ranges={state}
            />*/}
              
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default RangeDates;

