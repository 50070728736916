import React, { Fragment, useState, useRef, useEffect, FC } from "react";
import LocationInput from "../LocationInput";
//import GuestsInput from "../GuestsInput";
import StayDatesRangeInput from "./StayDatesRangeInput";
import { ClockIcon, MapPinIcon } from "@heroicons/react/24/outline";
import ClearDataButton from "../ClearDataButton";
import { Popover, Transition } from "@headlessui/react";
import ButtonSubmit from "../ButtonSubmit";
import { UserPlusIcon } from "@heroicons/react/24/outline";
import { GuestsObject } from "../type";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import { Link } from "react-router-dom";

// Location input props
export interface LocationInputProps {
  placeHolder?: string;
  desc?: string;
  className?: string;
  divHideVerticalLineClass?: string;
  autoFocus?: boolean;
}


// guest input props
export interface GuestsInputProps {
  fieldClassName?: string;
  className?: string;
  buttonSubmitHref?: string;
  hasButtonSubmit?: boolean;
}

const StaySearchForm: FC<{}> = () => {
  const containerRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
  
    const [value, setValue] = useState("");

    const refresh = () => 
    {
      return window.location.reload();
    }
    
  
 //start of location form definition
/*
  const LocationInput: FC<LocationInputProps> = ({
    autoFocus = false,
    placeHolder = "Location",
    desc = "Where are you going...?",
    className = "nc-flex-1.5",
    divHideVerticalLineClass = "left-10 -right-0.5",
  }) => {
    
    const [showPopover, setShowPopover] = useState(autoFocus);

    

      const regions=[{
                    "country":"South Africa",
                    "city":"Sandton"
                  },
                 
                 { "country":"South Africa",
                    "city":"Rosebank"   
                  },
                  { "country":"South Africa",
                  "city":"Bedfordview"   
                   },
                   { "country":"South Africa",
                   "city":"Edenvale"   
                 },
                 { "country":"South Africa",
                 "city":"Pretoria"   
                  },
  
    ];
    useEffect(() => {
      setShowPopover(autoFocus);
    }, [autoFocus]);
  
    useEffect(() => {
      if (eventClickOutsideDiv) {
        document.removeEventListener("click", eventClickOutsideDiv);
      }
      showPopover && document.addEventListener("click", eventClickOutsideDiv);
      return () => {
        document.removeEventListener("click", eventClickOutsideDiv);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showPopover]);
  
    useEffect(() => {
      if (showPopover && inputRef.current) {
        inputRef.current.focus();
      }
    }, [showPopover]);
  
    const eventClickOutsideDiv = (event: MouseEvent) => {
      if (!containerRef.current) return;
      // CLICK IN_SIDE
      if (!showPopover || containerRef.current.contains(event.target as Node)) {
        return;
      }
      // CLICK OUT_SIDE
      setShowPopover(false);
    };
  
    const handleSelectLocation = (item: string) => {
      setValue(item);
      setShowPopover(false);
      //alert(value);
    };
  
    const renderRecentSearches = () => {
      return (
        <>
          <h3 className="block mt-2 sm:mt-0 px-4 sm:px-8 font-semibold text-base sm:text-lg text-neutral-800 dark:text-neutral-100">
            Popular searches
          </h3>
          <div className="mt-2">
            {regions.map((item,index) => (
              <span
                onClick={() => handleSelectLocation(item.city)}
                key={item.city}
                className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
              >
                <span className="block text-neutral-400">
                  <ClockIcon className="h-4 sm:h-6 w-4 sm:w-6" />
                </span>
                <span className=" block font-medium text-neutral-700 dark:text-neutral-200">
                  {item.city}
                </span>
              </span>
            ))}
          </div>
        </>
      );
    };
  
    const renderSearchValue = () => {
      return (
        <>
          {[
            "Sandton",
            " Rosebank",
            "Bedfordview",
            "Edenvale",
            "Cape Town",
            "Namibia",
          ].map((item) => (
            <span
              onClick={() => handleSelectLocation(item)}
              key={item}
              className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
            >
              <span className="block text-neutral-400">
                <ClockIcon className="h-4 w-4 sm:h-6 sm:w-6" />
              </span>
              <span className="block font-medium text-neutral-700 dark:text-neutral-200">
                {item}
              </span>
            </span>
          ))}
        </>
      );
    };
  
    return (
      <div className={`relative flex ${className}`} ref={containerRef}>
        <div
          onClick={() => setShowPopover(true)}
          className={`flex z-10 flex-1 relative [ nc-hero-field-padding ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left  ${
            showPopover ? "nc-hero-field-focused" : ""
          }`}
        >
          <div className="text-neutral-300 dark:text-neutral-400">
            <MapPinIcon className="w-5 h-5 lg:w-7 lg:h-7" />
          </div>
          <div className="flex-grow">
            <input
              className={`block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-300 xl:text-lg font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate`}
              placeholder={placeHolder}
              value={value}
              autoFocus={showPopover}
              onChange={(e) => {
                setValue(e.currentTarget.value);
              }}
              ref={inputRef}
            />
            <span className="block mt-0.5 text-sm text-neutral-400 font-light ">
              <span className="line-clamp-1">{!!value ? placeHolder : desc}</span>
            </span>
            {value && showPopover && (
              <ClearDataButton
                onClick={() => {
                  setValue("");
                }}
              />
            )}
          </div>
        </div>
  
        {showPopover && (
          <div
            className={`h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 bg-white dark:bg-neutral-800 ${divHideVerticalLineClass}`}
          ></div>
        )}
  
        {showPopover && (
          <div className="absolute left-0 z-40 w-full min-w-[300px] sm:min-w-[500px] bg-white dark:bg-neutral-800 top-full mt-3 py-3 sm:py-6 rounded-3xl shadow-xl max-h-96 overflow-y-auto">
            {value ? renderSearchValue() : renderRecentSearches()}
          </div>
        )}
      </div>
    );
  };
  */

//end of location forms definitions


//start of input forms definitions

const GuestsInput: FC<GuestsInputProps> = ({
  fieldClassName = "[ nc-hero-field-padding ]",
  className = "[ nc-flex-1 ]",
  buttonSubmitHref = "/listing-stay-map",
  hasButtonSubmit = true,
}) => {
  const [guestAdultsInputValue, setGuestAdultsInputValue] = useState(2);
  const [guestChildrenInputValue, setGuestChildrenInputValue] = useState(0);
  //const [guestInfantsInputValue, setGuestInfantsInputValue] = useState();

  const handleChangeData = (value: number, type: keyof GuestsObject) => {
    let newValue = {
      guestAdults: guestAdultsInputValue,
      guestChildren: guestChildrenInputValue,
      //guestInfants: guestInfantsInputValue,
    };
    if (type === "guestAdults") {
      setGuestAdultsInputValue(value);
      newValue.guestAdults = value;
    }
    if (type === "guestChildren") {
      setGuestChildrenInputValue(value);
      newValue.guestChildren = value;
    }
    /*
    if (type === "guestInfants") {
      setGuestInfantsInputValue(value);
      newValue.guestInfants = value;
    }
    */
  };

  const totalGuests =
    guestChildrenInputValue + guestAdultsInputValue ; //+ guestInfantsInputValue;

  return (
    <Popover className={`flex relative ${className}`}>
      {({ open }) => (
        <>
          <div
            className={`flex-1 z-10 flex items-center focus:outline-none ${
              open ? "nc-hero-field-focused" : ""
            }`}
          >
            <Popover.Button
              className={`relative z-10 flex-1 flex text-left items-center ${fieldClassName} space-x-3 focus:outline-none`}
              onClickCapture={() => document.querySelector("html")?.click()}
            >
              <div className="text-neutral-300 dark:text-neutral-400">
                <UserPlusIcon className="w-5 h-5 lg:w-7 lg:h-7" />
              </div>
              <div className="flex-grow">
                <span className="block xl:text-lg font-semibold">
                  {totalGuests || ""} Guests
                </span>
                <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
                  {totalGuests ? "Guests" : "Add guests"}
                </span>
              </div>

              {!!totalGuests && open && (
                <ClearDataButton
                  onClick={() => {
                    setGuestAdultsInputValue(0);
                    setGuestChildrenInputValue(0);
                    //setGuestInfantsInputValue(0);
                  }}
                />
              )}
            </Popover.Button>

            {/* BUTTON SUBMIT OF FORM */}
            {hasButtonSubmit && (
              <div className="pr-2 xl:pr-4" >
                <Link 
                to={"/listing-stay"}
                state={{name:value,country:value}}
               
                type="button"
                className="h-14 md:h-16 w-full md:w-16 rounded-full bg-primary-6000 hover:bg-primary-700 flex items-center justify-center text-neutral-50 focus:outline-none"
              >
                <span className="mr-3 md:hidden" >Search</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </Link>
              </div>
            )}
          </div>

          {open && (
            <div className="h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 -left-0.5 right-0 bg-white dark:bg-neutral-800"></div>
          )}
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute right-0 z-10 w-full sm:min-w-[340px] max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl">
              <NcInputNumber
                className="w-full"
                defaultValue={guestAdultsInputValue}
                onChange={(value) => handleChangeData(value, "guestAdults")}
                max={10}
                min={1}
                label="Adults"
                desc="Ages 13 or above"
              />
              <NcInputNumber
                className="w-full mt-6"
                defaultValue={guestChildrenInputValue}
                onChange={(value) => handleChangeData(value, "guestChildren")}
                max={4}
                label="Children"
                desc="Ages 2–12"
              />

{/*
              <NcInputNumber
                className="w-full mt-6"
                defaultValue={guestInfantsInputValue}
                onChange={(value) => handleChangeData(value, "guestInfants")}
                max={4}
                label="Infants"
                desc="Ages 0–2"
              />

          */}

            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

//end of location forms definitions

  const renderForm = () => {
    return (
      <form className="w-full relative mt-8 flex rounded-full shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800 ">
        <LocationInput className="flex-[1.5]" />

        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
        <StayDatesRangeInput className="flex-1" />
        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
        <GuestsInput className="flex-1" />
      </form>
    );
  };

  return renderForm();
};

export default StaySearchForm;
