
import { createGlobalState} from 'react-hooks-global-state';

const {useGlobalState,setGlobalState}=createGlobalState({
    startDate:"",
    endDate:"",
    guests:null,
    price:null,
    user:null,
    currency:"AOA"
});

/*
const index=()=>{

};
*/

export {useGlobalState,setGlobalState};