import React from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import CommonLayout from "./CommonLayout";
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import LoadingSpinner from "shared/LoadingSpinner";
import PageLogin from "containers/PageLogin/PageLogin";
import { isLoggedIn,users } from "state/cartSore";
import { useRecoilValue ,useRecoilState} from "recoil";


const AccountBilling = () => {
  const [isLogged,setIsLogged]=useRecoilState<boolean>(isLoggedIn)


  const renderSection1 = () => {

    
    
  return (
    <div>
      
     
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">Payments & payouts</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="max-w-2xl">
            <span className="text-xl font-semibold block">Payout methods</span>
            <br />
            <span className="text-neutral-700 dark:text-neutral-300 block">
              When you receive a payment for a reservation, we call that payment
              to you a "payout." Our secure payment system supports several
              payout methods, which can be set up below. Go to FAQ.
              <br />
              <br />
              To get paid, you need to set up a payout method Airbnb releases
              payouts about 24 hours after a guest’s scheduled check-in time.
              The time it takes for the funds to appear in your account depends
              on your payout method. Learn more
            </span>
            <div className="pt-10">
              <ButtonPrimary>Add payout mothod</ButtonPrimary>
            </div>
          </div>
        </div>
   
    </div>
  );
};
return (
  <div>
     {
             // user !== undefined && isAuthenticated && (
                  
            isLogged  ?    
    <CommonLayout>{renderSection1()}</CommonLayout>
     
    :<PageLogin></PageLogin>
  }
  </div>
);
};



export default AccountBilling;
/*
export default withAuthenticationRequired(AccountBilling, {
  onRedirecting: () => <LoadingSpinner></LoadingSpinner>,
 // returnTo: 'http://localhost:3000/listing-stay-detail'
});
*/