import { Tab } from "@headlessui/react";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import React, { FC, Fragment, useEffect, useState } from "react";
import visaPng from "images/vis.png";
import mastercardPng from "images/mastercard.svg";
import { GuestsObject } from "components/HeroSearchForm/type";
import StartRating from "components/StartRating/StartRating";
import NcModal from "shared/NcModal/NcModal";
import ModalSelectDate from "components/ModalSelectDate";
import converSelectedDateToString from "utils/converSelectedDateToString";
import ModalSelectGuests from "components/ModalSelectGuests";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link ,useLocation,Navigate} from "react-router-dom";
import StayDatesRangeInput from "containers/ListingDetailPage/listing-stay-detail/StayDatesRangeInput";
import StayDates from "containers/ListingDetailPage/listing-stay-detail/StayDates";
import GuestsInput from "containers/ListingDetailPage/listing-stay-detail/GuestsInput";
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import LoadingSpinner from "shared/LoadingSpinner";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { Popover, Transition } from "@headlessui/react";
import { CalendarIcon } from "@heroicons/react/24/outline";
import DatePicker from "react-datepicker";
import ClearDataButton from "components/HeroSearchForm/ClearDataButton";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import { differenceInDays } from 'date-fns';
import moment from "moment";
import { dateState,dayState,endDateState} from "state/cartSore";
import { useRecoilState,useRecoilValue } from "recoil";



export interface CheckOutPagePageMainProps {
  className?: string;
}

const CheckOutTaxi: FC<CheckOutPagePageMainProps> = ({
  className = "",
}) => {


 

  const [guests] = useState<GuestsObject>({
    guestAdults: 2,
    guestChildren: 1,
    guestInfants: 1,
  });

  const location=useLocation();
  const details=location.state;

  const [startDate, setStartDate] = useRecoilState(dateState);
  const [endDate, setEndDate] =  useRecoilState(endDateState);

  /*
  const [startDate, setStartDate] = useState<Date | null>(
    new Date()
  );

 
  const [endDate, setEndDate] =  useState<Date | null>(
    new Date()
  );
  */
  const [numberDays,setnumberDays]=useRecoilState(dayState);
  
  /*
  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection"
}

const verifyDays=(days:any)=>{
  if(days==null || days==0)
  {
    setnumberDays(1);
  }
  return days;

}

  const onChangeDate = (dates: any) => {
    const [start, end] = dates;

    
    setStartDate(start);
    
   
/*
    const newData={
      starts:JSON.stringify(start),
      ends:JSON.stringify(end)
    }

  
    
    setEndDate(end);
   
    if(end==null)
    {
      const numberdays=calculateDaysLeft(start,start);
      setnumberDays(numberdays+1);
    }
    if(end!=null)
    {
      const numberdays=(calculateDaysLeft(start,end));
      setnumberDays(numberdays);
      verifyDays(numberdays);
    }
   
   
  };

  const calculateDaysLeft=(startDate:any,endDate:any) =>{
    if (!moment.isMoment(startDate)) startDate = moment(startDate);
    if (!moment.isMoment(endDate)) endDate = moment(endDate);

    return endDate.diff(startDate, "days");
  }
  const daysLeft = calculateDaysLeft(startDate, endDate);
  */
  useEffect(()=>{
    
console.log(details);
  if(location.state == undefined || location.state == null || location.state == ''){
    <Navigate to={'/'} />
  }
  },
  [])

  // funtion to render Dates
  const renderInput = () => {
    return (
      <>
        <div className="text-neutral-300 dark:text-neutral-400">
          <CalendarIcon className="w-5 h-5 lg:w-7 lg:h-7" />
        </div>
        <div className="flex-grow text-left">
          <span className="block xl:text-lg font-semibold">
            {startDate?.toLocaleDateString("en-US", {
              month: "short",
              day: "2-digit",
            }) || "Add dates"}
            {endDate
              ? " - " +
                endDate?.toLocaleDateString("en-US", {
                  month: "short",
                  day: "2-digit",
                })
              : ""}
          </span>
          <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
            {"Check in - Check out "}
            
          </span>
        </div>
      </>
      
    );
  };

  //render sidebar mobile
  const renderSidebarMobile = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
        <div className="flex flex-col sm:flex-row sm:items-center">
          
          
          <div className="flex-shrink-0 w-full sm:w-40">
            
           {/* <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
              <img
                alt=""
                className="absolute inset-0 object-cover"
                sizes="200px"
                src={details.thisState.galleryImgs[0]}
              />
    </div> */}
            <GallerySlider
          uniqueID={`StayCard_${details.thisState.id}`}
          ratioClass="aspect-w-4 aspect-h-3 "
          galleryImgs={details.thisState.data.photos}
          href={details.thisState.href}
        />
          </div>


          <div className="py-5 sm:px-5 space-y-3">
            <div>
              <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
             {details.title
             }
              </span>
              <span className="text-base font-medium mt-1 block">
                {//price.name
                }
              </span>
            </div>
            <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
            {details.thisState.bedrooms} Beds · {details.thisState.bathrooms} Baths
            </span>
            <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
            <StartRating />
          </div>
        </div>
        <div className="flex flex-col space-y-4">
        <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Nome - {details.thisState.price} X {numberDays}</span>
          </div>
          <h3 className="text-2xl font-semibold">AOA {details.thisState.price}</h3>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>AOA {details.thisState.price} X {numberDays}</span>
            <span>AOA {details.thisState.price*numberDays} </span>
          </div>

          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Service charges</span>
            <span>AOA 5.000</span>
          </div>

          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>Total</span>
            <span>AOA {(details.thisState.price*numberDays)+5000}</span>
          </div>
        </div>
      </div>
    );
  };
//end render sidbar mobile

  //render sidebar
   const renderSidebar = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
        <div className="flex flex-col sm:flex-row sm:items-center">
          
          
          <div className="flex-shrink-0 w-full sm:w-40">
            
           {/* <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
              <img
                alt=""
                className="absolute inset-0 object-cover"
                sizes="200px"
                src={details.thisState.galleryImgs[0]}
              />
    </div> */}
            <GallerySlider
          uniqueID={`StayCard_${details.thisState.id}`}
          ratioClass="aspect-w-4 aspect-h-3 "
          galleryImgs={details.thisState.data.photos}
          href={details.thisState.href}
        />
          </div>


          <div className="py-5 sm:px-5 space-y-3">
            <div>
              <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
             {details.title
             }
              </span>
              <span className="text-base font-medium mt-1 block">
                {//price.name
                }
              </span>
            </div>
            <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
            {details.thisState.bedrooms} Beds · {details.thisState.bathrooms} Baths
            </span>
            <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
            <StartRating />
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <h3 className="text-2xl font-semibold">AOA {details.thisState.price}</h3>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>AOA {details.thisState.price} X {numberDays}</span>
            <span>AOA {details.thisState.price*numberDays} </span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Service charges</span>
            <span>AOA 5.000</span>
          </div>

          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>Total</span>
            <span>AOA {(details.thisState.price*numberDays)+5000}</span>
          </div>
        </div>
      </div>
    );
  };
//end render sidbar
  const renderMain = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">
         Checkout!
        </h2>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <div>
            <h4 className="text-2xl font-semibold m-5">A sua viagem Viagem</h4>
            <div className="m-5">
            {renderSidebar()}
            </div>
            <NcModal
              renderTrigger={(openModal) => (
                <span
                  onClick={() => openModal()}
                  className="block lg:hidden underline  mt-1 cursor-pointer"
                >
                {// Ver Detalhes da Reserva
                }
                </span>
              )}
              renderContent={renderSidebar}
              modalTitle="Booking details"
            />
          </div>
      
              <form className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl ">

                {//callback to the fucntion that renders date with a a date popover attribute
                }
             <StayDatesRangeInput/>
             

              <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
              <GuestsInput className="flex-1" />
             
            </form>
        </div>

        <div>
          <div className="mt-6">
            <Tab.Group>
              <Tab.List className="flex my-5 gap-1">
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`px-4 py-1.5 sm:px-6 sm:py-2.5 rounded-full focus:outline-none ${
                        selected
                          ? "bg-neutral-800 dark:bg-neutral-200 text-white dark:text-neutral-900"
                          : "text-neutral-6000 dark:text-neutral-400 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                      }`}
                    >
                      Detalhes da Reserva
                    </button>
                  )}
                </Tab>
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`px-4 py-1.5 sm:px-6 sm:py-2.5  rounded-full flex items-center justify-center focus:outline-none  ${
                        selected
                          ? "bg-neutral-800 dark:bg-neutral-200 text-white dark:text-neutral-900"
                          : " text-neutral-6000 dark:text-neutral-400 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                      }`}
                    >
                      <span className="mr-2.5">Ja tem conta ? clique aqui</span>
                      <img className="w-8" src={visaPng} alt="visa" />
                      <img
                        className="w-8"
                        src={mastercardPng}
                        alt="mastercard"
                      />
                    </button>
                  )}
                </Tab>
              </Tab.List>

              <Tab.Panels>
                <Tab.Panel className="space-y-5">
                  <div className="space-y-1">
                    <Label>Nome Completo</Label>
                    <Input defaultValue="Primeiro e Ultimo nome" />
                  </div>
                  <div className="space-y-1">
                    <Label>Email </Label>
                    <Input defaultValue="reserva@gmail.com" />
                  </div>
                  <div className="flex space-x-5  ">
                    <div className="flex-1 space-y-1">
                      <Label>Telefone </Label>
                      <Input type="number" placeholder="933 00 00 00" />
                    </div>
                    <div className="flex-1 space-y-1">
                      <Label>Endereço</Label>
                      <Input />
                    </div>
                  </div>
                  <div className="space-y-1">
                    <Label>Mensagem de observação </Label>
                    <Textarea placeholder="..." />
                    <span className="text-sm text-neutral-500 block">
                      Write a few sentences about yourself.
                    </span>
                  </div>
                </Tab.Panel>
                <Tab.Panel className="space-y-5">
                  <div className="space-y-1">
                    <Label>Email </Label>
                    <Input type="email" defaultValue="example@gmail.com" />
                  </div>
                  <div className="space-y-1">
                    <Label>Password </Label>
                    <Input type="password" defaultValue="***" />
                  </div>
                  
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
            <div className="pt-8">
            <Link to="/pay-done" state={details} >
              <ButtonPrimary >Finalizar a reserva</ButtonPrimary>
              </Link>
            </div>
            <h4 className="text-1xl font-semibold m-10">Os Detalhes de pagamento sera enviado no seu email.</h4>
          
            <LoadingSpinner/>
          </div>

        </div>
      </div>
    );
  };

  return (
    <div className={`nc-CheckOutPagePageMain ${className}`}>
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">{renderMain()}</div>
        {//<div className="hidden lg:block flex-grow">{renderSidebar()}</div>
        }
      </main>
    </div>
  );
};

export default CheckOutTaxi;
/*
export default withAuthenticationRequired(CheckOutPagePageMain, {
  onRedirecting: () => <LoadingSpinner></LoadingSpinner>,
  returnTo: 'http://localhost:3000/login'
});
*/