import React, { useEffect, useState } from "react";
import MyRouter from "routers/index";
import axios from 'axios';
//import { UserContextProvider } from "./userContext";
axios.defaults.baseURL='https://barengue.onrender.com/api/';
//axios.defaults.withCredentials=true;


function App() {


  return (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">

      <MyRouter />

    </div>
  );
}

export default App;
