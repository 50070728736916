import { Tab } from "@headlessui/react";
//import { PencilSquareIcon } from "@heroicons/react/24/outline";
import React, { FC, Fragment, useEffect, useState,useRef } from "react";
import visaPng from "images/vis.png";
import mastercardPng from "images/mastercard.svg";
import { GuestsObject } from "components/HeroSearchForm/type";
import StartRating from "components/StartRating/StartRating";
import NcModal from "shared/NcModal/NcModal";
//import ModalSelectDate from "components/ModalSelectDate";
//import converSelectedDateToString from "utils/converSelectedDateToString";
//import ModalSelectGuests from "components/ModalSelectGuests";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link ,useLocation,Navigate} from "react-router-dom";
import StayDatesRangeInput from "containers/ListingDetailPage/listing-stay-detail/StayDatesRangeInput";
//import StayDates from "containers/ListingDetailPage/listing-stay-detail/StayDates";
import GuestsInput from "containers/ListingDetailPage/listing-stay-detail/GuestsInput";
//import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import LoadingSpinner from "shared/LoadingSpinner";
import GallerySlider from "components/GallerySlider/GallerySlider";
//import { Popover, Transition } from "@headlessui/react";
import { CalendarIcon } from "@heroicons/react/24/outline";
//import DatePicker from "react-datepicker";
//import ClearDataButton from "components/HeroSearchForm/ClearDataButton";
//import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
//import DatePickerCustomDay from "components/DatePickerCustomDay";
//import { differenceInDays } from 'date-fns';
//import moment from "moment";
import { useNavigate } from "react-router-dom";


import { dateState,dayState,endDateState,isLoggedIn,users} from "state/cartSore";
import { useRecoilState,useRecoilValue } from "recoil";
import emailjs from '@emailjs/browser';
import sendgrid from '@sendgrid/mail';
import axios from "axios";
import RangeDates from "containers/ListingDetailPage/listing-stay-detail/RangeDates";
import moment from "moment";
import addDays from "date-fns/addDays";





export interface CheckOutPagePageMainProps {
  className?: string;
}

const CheckOutPagePageMain: FC<CheckOutPagePageMainProps> = ({
  className = "",
}) => {

  



  const [guests] = useState<GuestsObject>({
    guestAdults: 2,
    guestChildren: 1,
    guestInfants: 1,
  });

  const navigate=useNavigate();
  const location=useLocation();
  const details=location.state;
  const[ isLogged,setIsLogged]=useRecoilState(isLoggedIn)
  const [startDate, setStartDate] = useRecoilState(dateState);
  const [endDate, setEndDate] =  useRecoilState(endDateState);
  const [numberDays,setnumberDays]=useRecoilState(dayState);
  const[username,setUsername]=useState("");
  const [email,setEmail]=useState("")
  const [phone,setPhone]=useState("")
  const [password,setPassword]=useState("");
  const[userData,setUser]=useRecoilState<any>(users);
  const [loading,setLoading]=useState(false);
  const [references,setReferences]=useState("");
  
const profile=useRecoilValue(users);


const serviceId="service_hxqvoys"
const templateId="template_xcdtizc"
const privateKey="v9XqmBONzbdsIUhzf"




const listing=details.docStays.map((pic:any)=>{
  return pic.name
    })


  const sendEmails = () => {
    const templateParams={
      from_name:JSON.stringify("floriano.neve@icloud.com"),
      from_email:JSON.stringify("florianonevee@gmail.com"),
      to_email:JSON.stringify("florianonevee@gmail.com"),
      to_name:JSON.stringify("floriano.neve@icloud.com"),
      message:"Detalhes do Booking \n \n Check-in "+startDate.toLocaleDateString("en-US", {
        month: "short",
        day: "2-digit",
      })+
      "\n Checkout- "
      +endDate.toLocaleDateString("en-US", {
        month: "short",
        day: "2-digit",
      })+
      "\n Dias: "+
      numberDays+
      " \n Local "+listing[0]+
      "\n Valor Total: AOA "+
     (( details.totalP*numberDays)+5000)+ ""
    }

    emailjs.send("service_hxqvoys","template_xcdtizc",{
      to_name: profile.name,
      from_name: profile.email,
      message:"Detalhes do Booking \n \n Check-in "+startDate.toLocaleDateString("en-US", {
        month: "short",
        day: "2-digit",
      })+
      "\n Checkout- "
      +endDate.toLocaleDateString("en-US", {
        month: "short",
        day: "2-digit",
      })+
      "\n Dias: "+
      numberDays+
      " \n Local "+listing[0]+
      "\n Valor Total: AOA "+
     (( details.totalP*numberDays)+5000)+ ""
    }
,privateKey);

   /*
      emailjs.send(serviceId,templateId, templateParams, privateKey)
      .then((result) => {
          console.log("email: "+result.text);
          console.log(templateParams.from_email);
         
      }, (error) => {
          console.log(error.text);
      });
      */
      
  };

  const login=async ()=>{
    
    try{
      setLoading(true)
      const res=await axios.post('/auth/login',{
        
        username,
        password,} );
  
      const data={
        name:res.data.details.username,
        email:res.data.details.email,
        phone:res.data.details.phone,
      }
      localStorage.setItem("user",JSON.stringify(data));
      localStorage.setItem("isLoggedIn", JSON.stringify(true));
      setIsLogged(true);
      setUser(data)
     // alert("Login com sucesso.")
     setLoading(false)
    }
    
    catch(e){
      setLoading(false)
      console.log(e);
      alert("Erro ao fazer o Login, por favor tente mais tarde")
    }
  }
  
  const register= async ()=>{

   
      try{
            setLoading(true)
            await axios.post('/auth/register',{
              username,
              email,
              password,
              phone,
            });

            login();
            
            //end LOGIN
  
        }
      catch(e){
        setLoading(true)
    console.log(e);
    alert("Erro ao fazer o registro, por favor tente mais tarde")
    setLoading(false)
             } 
  }

  const pay=(details.totalP*numberDays)+5000;

  const gallery=details.docStays.map((pic:any)=>{
    return pic.photos
      })

      const id=details.docStays.map((pic:any)=>{
        return pic._id
          })

  const saveBooking=async ()=>{
    
    const booking={
      place:id,
      checkin:startDate,
      checkout:endDate,
      name:profile.name,
      email:profile.email,
      phone:profile.phone,
      reference:references,
      price:pay,
      type:"stays",
      status:"Pending"
  
    }
    console.log(booking);
    try{
       
             
        const book=await axios.post("/bookings",booking);
        alert("Booking feito com sucesso");
        handleClick();
        sendEmails();
    }
    catch(err:any){
     alert("Erro ao Efectuar o Booking, por favor tente mais tarde")
  console.log(err)
    }
  }


  const getDatesInRange = (startDate:any, endDate:any) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    const date = new Date(start.getTime());

    const dates = [];

    while (date <= end) {
      dates.push(new Date(date).getTime());
      date.setDate(date.getDate() + 1);
    }

    return dates;
  };

  const alldates = getDatesInRange(startDate,endDate);

  const handleClick = async () => {
    
    try {
      await Promise.all(
        details.selectedRooms.map((roomId:any) => {
          const res = axios.put(`/rooms/availability/${roomId.roomId}`, {
            dates: alldates,
          });
         
          console.log(alldates);
          return res;
        })
      );
      //setOpen(false);
      //navigate("/");
    } catch (err) {}
  };

  const calculateDaysLeft=(startDate:any,endDate:any) =>{
    if (!moment.isMoment(startDate)) startDate = moment(startDate);
    if (!moment.isMoment(endDate)) endDate = moment(endDate);

    return endDate.diff(startDate, "days");
  }


  const compare=()=>{
    if(endDate==startDate)
    {
      setEndDate(addDays(startDate,1));
    }
    const numberdays=(calculateDaysLeft(startDate,endDate));
    setnumberDays(numberdays)
    
  }

  

  
    

  useEffect(()=>{
    setReferences( "#BAR"+ Math.random().toString(36).substring(2,10)) ;
    //console.log("referencia "+references);
    compare();
    console.log(id[0])
console.log(profile.email);
  if(location.state == undefined || location.state == null || location.state == ''){
    <Navigate to={'/'} />
  }
  },
  [startDate,endDate])

  // funtion to render Dates
  const renderInput = () => {
    return (
      <>
        <div className="text-neutral-300 dark:text-neutral-400">
          <CalendarIcon className="w-5 h-5 lg:w-7 lg:h-7" />
        </div>
        <div className="flex-grow text-left">
          <span className="block xl:text-lg font-semibold">
            {startDate?.toLocaleDateString("en-US", {
              month: "short",
              day: "2-digit",
            }) || "Add dates"}
            {endDate
              ? " - " +
                endDate?.toLocaleDateString("en-US", {
                  month: "short",
                  day: "2-digit",
                })
              : ""}
          </span>
          <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
            {"Check in - Check out "}
            
          </span>
        </div>
      </>
      
    );
  };

  //render sidebar mobile
  const renderSidebarMobile = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
        <div className="flex flex-col sm:flex-row sm:items-center">
          
          
          <div className="flex-shrink-0 w-full sm:w-40">
            
           {/* <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
              <img
                alt=""
                className="absolute inset-0 object-cover"
                sizes="200px"
                src={details.thisState.galleryImgs[0]}
              />
    </div> */}
            <GallerySlider
          uniqueID={`StayCard_${id[0]}`}
          ratioClass="aspect-w-4 aspect-h-3 "
          galleryImgs={gallery[0]}
          href={""}
        />
          </div>


          <div className="py-5 sm:px-5 space-y-3">
            <div>
              <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
              {
          details.docStays.map((stays:any)=>{
            return( stays.title)
          })
        }
              </span>
              <span className="text-base font-medium mt-1 block">
                {//price.name
                }
              </span>
            </div>
            <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
            {
          details.docStays.map((stays:any)=>{
            return( stays.bedrooms)
          })
        }Beds· {
          details.docStays.map((stays:any)=>{
            return( stays.bathrooms)
          })
        }
        Baths
            </span>
            <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
            <StartRating />
          </div>
        </div>
        <div className="flex flex-col space-y-4">
        <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Nome - {details.thisState.price} X {numberDays}</span>
          </div>
          <h3 className="text-2xl font-semibold">AOA {details.totalP}</h3>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>AOA {details.totalP} XS {numberDays}</span>
            <span>AOA {details.thisState.price*numberDays} </span>
          </div>

          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Service charges</span>
            <span>AOA 6.000</span>
          </div>

          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>Total</span>
            <span>AOA {(details.thisState.price*numberDays)+6000}</span>
          </div>
        </div>
      </div>
    );
  };

//end render sidbar mobile


  //render sidebar
   const renderSidebar = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
        <div className="flex flex-col sm:flex-row sm:items-center">
          
          
          <div className="flex-shrink-0 w-full sm:w-40">
            
           {/* <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
              <img
                alt=""
                className="absolute inset-0 object-cover"
                sizes="200px"
                src={details.thisState.galleryImgs[0]}
              />
    </div> */}
            <GallerySlider
          uniqueID={`StayCard_${id[0]}`}
          ratioClass="aspect-w-4 aspect-h-3 "
          galleryImgs={gallery[0]}
          href={""} 
        />
          </div>


          <div className="py-5 sm:px-5 space-y-3">
          <div>
                <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                {
          details.docStays.map((stays:any)=>{
            return( stays.title)
          })
        }
        
                </span>
                <span className="text-base sm:text-lg font-medium mt-1 block">
                {
          details.docStays.map((stays:any)=>{
            return( stays.name)
          })
        }
                </span>
              </div>
            <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
            {details.selectedRooms.length} Quartos · {
          details.docStays.map((stays:any)=>{
            return( stays.city)
          })
        }
            </span>
            <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
            <StartRating />
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <h3 className="text-2xl font-semibold">Rooms: {details.selectedRooms.length}</h3>
          
         { details.selectedRooms.map((rooms:any)=>
         

         <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
         <span>{rooms.title} </span>
         <span> X </span>
         <span>AOA {rooms.price} </span>
         </div>
         
         )
        
          }

          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
         <span> </span>
         <span>  </span>
         <span>AOA {details.totalP} </span>
         </div>

          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
          
            <span>AOA {details.totalP} X {numberDays} days</span>
            <span>AOA {details.totalP*numberDays} </span>
          </div>

          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Service charges</span>
            <span>AOA 5.000</span>
          </div>

          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>Total</span>
            <span>AOA {(details.totalP*numberDays)+5000}</span>
          </div>
        </div>
      </div>
    );
  };
//end render sidbar
  const renderMain = () => {
    return (
     
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
         
        
        
        <h2 className="text-3xl lg:text-4xl font-semibold">
         Checkout!
        </h2>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <div>
            <h4 className="text-2xl font-semibold m-5">A sua viagem Viagem</h4>
            <div className="m-5">
            {renderSidebar()}
            </div>
            <NcModal
              renderTrigger={(openModal) => (
                <span
                  onClick={() => openModal()}
                  className="block lg:hidden underline  mt-1 cursor-pointer"
                >
                {// Ver Detalhes da Reserva
                }
                </span>
              )}
              renderContent={renderSidebar}
              modalTitle="Booking details"
            />
          </div>
      
              <form className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl ">

                {//callback to the fucntion that renders date with a a date popover attribute
                }
             <RangeDates/>
             

              <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
              <GuestsInput className="flex-1" />
             
            </form>
        </div>

{// check if user logged in
}

{isLogged!=true ?
        <div>
          <div className="mt-6">
            <Tab.Group>
              <Tab.List className="flex my-5 gap-1">
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`px-4 py-1.5 sm:px-6 sm:py-2.5 rounded-full focus:outline-none ${
                        selected
                          ? "bg-neutral-800 dark:bg-neutral-200 text-white dark:text-neutral-900"
                          : "text-neutral-6000 dark:text-neutral-400 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                      }`}
                    >
                      Abrir conta 
                    </button>
                  )}
                </Tab>
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`px-4 py-1.5 sm:px-6 sm:py-2.5  rounded-full flex items-center justify-center focus:outline-none  ${
                        selected
                          ? "bg-neutral-800 dark:bg-neutral-200 text-white dark:text-neutral-900"
                          : " text-neutral-6000 dark:text-neutral-400 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                      }`}
                    >
                      <span className="mr-2.5">Ja tem conta ? clique aqui</span>
                      <img className="w-8" src={visaPng} alt="visa" />
                      <img
                        className="w-8"
                        src={mastercardPng}
                        alt="mastercard"
                      />
                    </button>
                  )}
                </Tab>
              </Tab.List>

              <Tab.Panels>
                <Tab.Panel className="space-y-5">
                  
                  <div className="space-y-1">
                    <Label>Nome Completo</Label>
                    <Input placeholder="Primeiro e Ultimo nome"
                     onChange={ev=>setUsername(ev.target.value)}  /> 
                  </div>
                  <div className="space-y-1">
                    <Label>Email </Label>
                    <Input placeholder="reserva@gmail.com"
                     onChange={ev=>setEmail(ev.target.value)} /> 
                  </div>

                  <div className="flex space-x-5  ">
                    <div className="flex-1 space-y-1">
                      <Label>Telefone </Label>
                      <Input type="number" 
                       onChange={ev=>setPhone(ev.target.value)}placeholder="933 00 00 00" />
                    </div>
                  </div>

                  <div className="flex space-x-5  ">
                    <div className="flex-1 space-y-1">
                      <Label>Password </Label>
                      <Input 
                          required
                          value={password}
                          onChange={ev=>setPassword(ev.target.value)}
                          type="password" className="mt-1" />
                    </div>
                  </div>

                  <span className="mr-2.5 m-5">Faça o registro para finalizar o seu booking</span>

                  <div className="flex space-x-5  ">


                  <ButtonPrimary loading={loading} onClick={()=>register()} >Register here</ButtonPrimary>

                  {/*<Link  className="mt-3" to="/pay-done" state={details} >
              <ButtonPrimary loading={loading} onClick={()=>register} >Register</ButtonPrimary>
              </Link>*/}

              </div>

                  
                </Tab.Panel>
                <Tab.Panel className="space-y-5">
                  <div className="space-y-1">
                    <Label>Email </Label>
                    <Input
                        type="text"
                        value={username}
                        onChange={ev=>setUsername(ev.target.value)}
                        placeholder="example@example.com"
                        className="mt-1"
                      />
                  </div>
                  <div className="space-y-1">
                    <Label>Password </Label>
                    <Input 
                    type="password" 
                    className="mt-1"
                    onChange={ev=>setPassword(ev.target.value)}
               />
                  </div>

                  <div className="flex space-x-5  ">
                  {//<Link to="/pay-done" state={details} </Link> >
                  }
              <ButtonPrimary loading={loading} onClick={()=>login()} >Login </ButtonPrimary>
              
              </div>
                </Tab.Panel>

               
              </Tab.Panels>
            </Tab.Group>
           
            <div className="pt-8">
           

             
             
         
            </div>
            
          
           
          </div>



        </div>
        :<span>
            
            <button
                      className="px-4 py-1.5 justify-center sm:px-6 sm:py-2.5 rounded-full focus:outline-none bg-neutral-400 dark:bg-neutral-200 text-white dark:text-neutral-900 m-5"
                         
                    >
                      Detalhes Pessoais da Reserva:
                    </button>

              
                   <div className="space-y-1">
                  
                    <Label>Nome Completo</Label>
                    <Input disabled defaultValue={profile.name} />
                  </div>
                  <div className="space-y-1">
                    <Label>Email </Label>
                    <Input  disabled defaultValue={profile.email} />
                  </div>
                  <div className="flex space-x-5  ">
                    <div className="flex-1 space-y-1">
                      <Label>Telefone </Label>
                      <Input disabled type="number" placeholder={profile.phone} />
                    </div>

                   {/* <div className="flex-1 space-y-1">
                      <Label>Endereço</Label>
                      <Input />
                    </div> */}

                      
                  </div>
                  
                  <div className="flex space-x-5  ">
                  <Link to="/pay-done" state={{...details,references}} className="mt-5"> 
                  <ButtonPrimary  onClick={()=>saveBooking()} >Finalizar a Reserva</ButtonPrimary>
                        
                      </Link> 
                      </div>
                  
           

                      

        </span>
        
        }
        {// cend heck if user logged in
}
      </div>
    );
  };

  return (
    <div className={`nc-CheckOutPagePageMain ${className}`}>
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">{renderMain()}</div>
        {//<div className="hidden lg:block flex-grow">{renderSidebar()}</div>
        
        }
      </main>
    </div>
  );
};

export default CheckOutPagePageMain;
/*
export default withAuthenticationRequired(CheckOutPagePageMain, {
  onRedirecting: () => <LoadingSpinner></LoadingSpinner>,
  returnTo: 'http://localhost:3000/login'
});
*/