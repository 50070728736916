import React, { FC, useEffect, useState } from "react";
import { DEMO_EXPERIENCES_LISTINGS } from "data/listings";
import { ExperiencesDataType, StayDataType } from "data/types";
import Pagination from "shared/Pagination/Pagination";
import TabFilters from "./TabFilters";
import Heading2 from "components/Heading/Heading2";
import ExperiencesCard from "components/ExperiencesCard/ExperiencesCard";
import useFetch from "hooks/useFecth";
import { AttractionName,AttractionTypes,rangeAttraction,tabTime,locationStays } from "state/cartSore";
import { useRecoilState ,useRecoilValue} from "recoil";
import axios from "axios";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import useFetchStay from "hooks/useFecthStay";

export interface SectionGridFilterCardProps {
  className?: string;
  data?: StayDataType[];
}

const DEMO_DATA: ExperiencesDataType[] = DEMO_EXPERIENCES_LISTINGS.filter(
  (_, i) => i < 8
);

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
  data ,
}) => {

  const[cardata,setCar]=useState([]);
  const rangePrices =useRecoilValue(rangeAttraction);
  const types=useRecoilValue(AttractionTypes);
  const timeDay=useRecoilValue(tabTime);
  const city=useRecoilValue(locationStays);

  const {docStays, loading, errorStays, reFetchStays}=useFetchStay('/newAttractions',types,rangePrices)


  const fetchListings = async () => {
    try {
      const response = await axios.get('/newAttractions', {
        params: {
          city:city,
          type:types,
          priceRange:rangePrices,
          timeDay:timeDay,
        }
      });
     setCar(response.data);
      //console.log(response.data)
    } catch (error) {
      console.error('Error fetching listings:', error);
    }
    
  };
  useEffect(()=>{

   console.log(timeDay);

//console.log(docStays);
    fetchListings()

  },[types,rangePrices,timeDay,city])
  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      <Heading2
        heading="Atraçōes"
        subHeading={
          <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
            233 experiences
            <span className="mx-2">·</span>
            Aug 12 - 18
            <span className="mx-2">·</span>2 Guests
          </span>
        }
      />

      <div className="mb-8 lg:mb-11">
        <TabFilters />
      </div>

      {loading ? (
       <ButtonPrimary loading={loading}>Loading</ButtonPrimary>
      
      ) : 
      <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">

        { cardata.length>0 ?  

            cardata.map((cars:any,id:any) => (
             
              <ExperiencesCard key={id} data={cars} />
              
            ))
        

         :
         <ButtonPrimary loading={true}>Nenhum item encontrado na sua procura</ButtonPrimary>
        }

      </div>
          }       
{/*
      <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 1xl:grid-cols-4">
        {cardata.map((stay,id) => (
          <ExperiencesCard key={id} data={stay} />
         
        ))}
      </div>*/}


      <div className="flex mt-16 justify-center items-center">
        <Pagination />
      </div>
    </div>
  );
};

export default SectionGridFilterCard;
