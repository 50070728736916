import React, { ReactNode, useEffect,useState } from "react";
import { imageGallery as listingStayImageGallery } from "./listing-stay-detail/constant";
import { imageGallery as listingCarImageGallery } from "./listing-car-detail/constant";
import { imageGallery as listingExperienceImageGallery } from "./listing-experiences-detail/constant";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ListingImageGallery, { DEMO_IMAGE } from "components/ListingImageGallery/ListingImageGallery";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import MobileFooterSticky from "./(components)/MobileFooterSticky";
import { ListingGalleryImage } from "components/ListingImageGallery/utils/types";
import useFetchStays from "hooks/useFecthStays";
import useFetchAttractions from "hooks/useFecthAttractions";
import useFetchCars from "hooks/useFecthCars";
import { useParams } from "react-router-dom";
import axios from "axios";

const DetailPagetLayout = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const params=useParams();
 // const [thisState,setThisState]=useState(useLocation().state);
  const thisPathname = useLocation().pathname;
  const [searchParams] = useSearchParams();
  const modal = searchParams?.get("modal");
  const searchStays=`/newplaces?_id=${params.id}`;
  const searchCars=`/rental?_id=${params.id}`;
  const {docStays,loadingStays,reFetchStays}=useFetchStays(searchStays);
  const {docCars,loadingCars,reFetchCars}=useFetchCars(searchCars);
  const [cars,setCars]=useState<any[]>([]);
  const searchAtt=`/attractions?_id=${params.id}`;
  const searcRental=`/rental?_id=${params.id}`;
  const {docAttractions,loadingAttractions,reFetchAttractions}=useFetchAttractions(searchAtt);
  

  const [disc, setDisc] = useState<any[]>([]);
  const [discA, setDiscA] = useState<any[]>([]);
  const [discR, setDiscR] = useState<any[]>([]);

  const handleCloseModalImageGallery = () => {
    let params = new URLSearchParams(document.location.search);
    params.delete("modal");
    navigate(`${thisPathname}/?${params.toString()}`);
  };

  
  


   
  
  const DEMO_IMAGE: ListingGalleryImage[] = [...disc].map(
    (item, index): ListingGalleryImage => {
      
      return {
        id: index,
        url: item,
      };
    }
  );

  const DEMO_IMAGEA: ListingGalleryImage[] = [...discA].map(
    (item, index): ListingGalleryImage => {
      
      return {
        id: index,
        url: item,
      };
    } 
  );


  const DEMO_IMAGER: ListingGalleryImage[] = [...discR].map(
    (item, index): ListingGalleryImage => {
      
      return {
        id: index,
        url: item,
      };
    }
  );
 /*
  const imageGallery: ListingGalleryImage[] = [...thisState.data.photos].map(
    (item ,index): ListingGalleryImage => {
      return {
        id: index,
        url: item,
      };
    }
  );
  */

  const getImageGalleryListing = () => {
    if (thisPathname?.includes("/listing-stay-details/")) {
      return DEMO_IMAGE;
    }
    if (thisPathname?.includes("/listing-car-details")) {
      return DEMO_IMAGER;
    }
    if (thisPathname?.includes("/listing-experiences-details")) {
      return DEMO_IMAGEA;
    }

    return [];
  };

  
  

  useEffect(()=>{

    const fetchData = async () => {
      
      try {
        const res = await axios.get(`/rental?_id=${params.id}`);
        //console.log(res.data);
        setCars(res.data);
      } catch (err:any) {
        
        alert("No internet connection")
      }
      //setLoading(false);
    };
    fetchData();

    
   
    const getvalue=()=>{
      docStays.map((stays:any)=>{
        setDisc(stays.photos);
       // console.log(stays.photos)
      })
    }

    const getvalueA=()=>{
      docAttractions.map((stays:any)=>{
        setDiscA(stays.photos);
       // console.log(stays.photos)
      })
    }
  
    const getvalueR=()=>{
      cars.map((stays:any)=>{
        setDiscR(stays.photos);
       // console.log(stays.photos)
      })
    }

    getvalue();
    getvalueA();
    getvalueR();
  
    //console.log("pics");
    //console.log(DEMO_IMAGE)
    //console.log(DEMO_IMAGER)
    //console.log(DEMO_IMAGEA)



   // getvalues();
    //console.log(disc);

    
    
  },[cars,docAttractions,docStays])

  return (
    <div className="ListingDetailPage">
      <ListingImageGallery
        isShowModal={modal === "PHOTO_TOUR_SCROLLABLE"}
        onClose={handleCloseModalImageGallery}
        images={getImageGalleryListing()}
      />

      <div className="container ListingDetailPage__content">{children}</div>

      {/* OTHER SECTION 
      <div className="container py-24 lg:py-32">
        <div className="relative py-16">
          <BackgroundSection />
          <SectionSliderNewCategories
            heading="Explore by types of stays"
            subHeading="Explore houses based on 10 types of stays"
            categoryCardType="card5"
            itemPerRow={5}
            sliderStyle="style2"
            uniqueClassName="ListingDetailPage"
          />
        </div>
        <SectionSubscribe2 className="pt-24 lg:pt-32" />
      </div>

      {/* STICKY FOOTER MOBILE 
      <MobileFooterSticky />
      */}
    </div>
  );
};

export default DetailPagetLayout;
