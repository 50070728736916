
import { useEffect, useState } from "react";
import axios from "axios";
import { AnyARecord } from "dns";

const useFetchStays = (url:any) => {
  const [docStays, setDoc] = useState([]);
  const [loadingStays, setLoading] = useState(false);
  const [errorStays, setError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await axios.get(url);
        setDoc(res.data);
      } catch (err:any) {
        setError(err);
        alert("No internet connection")
      }
      setLoading(false);
    };
    fetchData();
  }, [url]);
  

  const reFetchStays = async () => {
    setLoading(true);
    try {
      const res = await axios.get(url);
      setDoc(res.data);
    } catch (err:any) {
      setError(err);
    }
    setLoading(false);
  };

  return { docStays, loadingStays, errorStays, reFetchStays };
};

export default useFetchStays;
