import React, { FC, Fragment, useEffect, useState } from "react";
import CommentListing from "components/CommentListing/CommentListing";
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";
import StartRating from "components/StartRating/StartRating";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import LikeSaveBtns from "components/LikeSaveBtns";
import SectionDateRange from "../SectionDateRange";
import StayDatesRangeInput from "./StayDatesRangeInput";
import { useLocation, useNavigate } from "react-router-dom";
import { Amenities_demos, PHOTOS } from "./constant";
import { Dialog, Transition } from "@headlessui/react";
import { ArrowRightIcon, Squares2X2Icon } from "@heroicons/react/24/outline";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import ButtonCircle from "shared/Button/ButtonCircle";
import Input from "shared/Input/Input";
import { format } from "date-fns";
import { Popover} from "@headlessui/react";
import { CalendarIcon } from "@heroicons/react/24/outline";
import DatePicker from "react-datepicker";
import { UserPlusIcon } from "@heroicons/react/24/outline";
import "./header.css";
import RangeDates from "./RangeDates";

//import ClearDataButton from "components/HeroSearchForm/ClearDataButton";
//import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
//import DatePickerCustomDay from "components/DatePickerCustomDay";
import ClearDataButton from "components/HeroSearchForm/ClearDataButton";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import { differenceInDays } from 'date-fns';
import moment from "moment";
import { dateState,endDateState,dayState,cartStateRoom} from "state/cartSore";
import { useRecoilState } from "recoil";
//import GallerySlider from "components/GallerySlider/GallerySlider";
import axios from "axios";
import { DateRange } from "react-date-range";
import CheckBoxs from "shared/Checkbox/CheckBoxs";
import Checked from "shared/Checkbox/Checked";
import addDays from "date-fns/addDays";
import { useParams } from "react-router-dom";



import DetailPagetLayout from "../Layout";


import GuestsInput from "./GuestsInput"; 

import ModalReserveMobile from "../(components)/ModalReserveMobile";
import ModalSelectDate from "components/ModalSelectDate";
import converSelectedDateToString from "utils/converSelectedDateToString";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonThird from "shared/Button/ButtonThird";
//import ButtonCircle from "shared/Button/ButtonCircle";
import { useGlobalState } from "state";
import { Link } from "react-router-dom";
import useFetch from "hooks/useFecth";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { Alert } from "shared/Alert/Alert";
import useFetchStays from "hooks/useFecthStays";
import { Console } from "console";
//import ButtonClose from "shared/ButtonClose/ButtonClose";

//Listing details Edit
const StayDetailPageContainer: FC<{}> = () => {
  //
  
  
const users1=useGlobalState('startDate');
const users=useGlobalState('endDate');
  let [isOpenModalAmenities, setIsOpenModalAmenities] = useState(false);
  const [thisState,setThisState]=useState(useLocation().state);
  const thisPathname = useLocation().pathname;
  //const [stay,setStay]=useState(useLocation().state);
  const [Details,setDetails]=useState(!null);
  //const [data,setData]=useState(thisState);
  const params=useParams();
  const [startDate, setStartDate] = useRecoilState<any>(dateState);
  const [endDate, setEndDate] =  useRecoilState<any>(endDateState);
  const [numberDays,setnumberDays]=useRecoilState(dayState);
  const [roomCart,setRoomCart]=useState<any[]>([]);
  const { doc, loading, error } = useFetch(`/hotels/room/${params.id}`);
  const [available,setAvailable]=useState([]);
  const[totalP,setTotalP]=useState<any>();
  const [selectedRooms, setSelectedRooms] = useState<any>([]);
  const [openDate, setOpenDate] = useState(false);
  const [cartState,setCart]=useState<any>([]);
  const [roomsCart,setRoomsCart]=useState<any[]>([]);
  const [backup,setBeckup]=useState<any[]>([]);
  const [_id,setId]=useState(params.id);
  
 

  const teste=params;

  const searchStays=`/hotels?_id=${params.id}`;
    //const {docStayss,loadingStays,reFetchStays}=useFetchStays(searchStays);
    const [docStays, setStays] = useState<any[]>([]);
    const [disc, setDisc] = useState<any[]>([]);
    const [test,setTest]=useState<any[]>([]);
   
  
  const [dates, setDates] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },]);

  
  const getDatesInRange = (startDate:any, endDate:any) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    const date = new Date(start.getTime());

    const dates = [];

    while (date <= end) {
      dates.push(new Date(date).getTime());
      date.setDate(date.getDate() + 1);
    }
    return dates;
  };



  const alldates = getDatesInRange(startDate,endDate);
 
  const isAvailable = (roomNumber:any) => {
    const isFound = roomNumber.unavailableDates.some((date:any) =>
      alldates.includes(new Date(date).getTime())
    );

    return !isFound;
  };

  
  

  const handleClick = async () => {
    
    try {
      await Promise.all(
        selectedRooms.map((roomId:any) => {
          const res = axios.put(`/rooms/availability/${roomId.roomId}`, {
            dates: alldates,
          });
          alert("room added");
          console.log(alldates);
          return res;
        })
      );
      //setOpen(false);
      //navigate("/");
    } catch (err) {}
  };

  const handleSelects = (e:any) => {
    const checked  = e.target.checked;
    const value = e.target.value;
    
    const betterJson = value.split(',');
   
  }
    

  const handleSelect = (e:any) => {
    const checked  = e.target.checked;
    const value = e.target.value;
    
    const checkCart = [...selectedRooms].filter((i) => {
      return i == value;
  });

  if (checkCart.length !== 0) {
    alert("Room Removed successfully");
    const newCart = selectedRooms.filter((person:any) => person !== value);
   //console.log(newCart);
   setSelectedRooms(newCart);
   setCart(newCart)
   //console.log(cartState);
  } else {
    
      setSelectedRooms([... selectedRooms,value]);
      setCart([... selectedRooms,value]);
      alert("Room Reserved successfully");
  }
  

    
  };

  const addHotel= async(room:any)=>{
  
    const newRoom={
      ...room,
      status:"Reserved",
     
    }
  
    const checkCart = [...roomsCart].filter((i) => {
      return i.hotel_id == newRoom.hotel_id;
  });
  
  if (checkCart.length !== 0) {
    alert("Room is already in cart");
    
  } else {
    
      setRoomCart([...roomsCart,newRoom]);
      alert(room.title +" Reserved successfully"+newRoom.hotel_id);
  }
  

  }


  const addRoomss= async(room:any)=>{
  
    const newRoom={
      ...room,
      status:"Reserved",
     
    }
  
    const checkCart = [...roomCart].filter((i) => {
      return i.title == newRoom.title;
  });
  
  if (checkCart.length !== 0) {
    alert("Room is already in cart");
    
  } else {
    
      setRoomCart([...roomCart,newRoom]);
      alert(room.title +" Reserved successfully"+newRoom._id);
      const res = await axios.put(`/rooms/booked/${newRoom._id}`, {
        dates: alldates }); 
  }
  
  
  }


const addRoom= async(room:any)=>{
  
  const newRoom={
    ...room,
    status:"Reserved",
   
  }

  const checkCart = [...roomCart].filter((i) => {
    return i.title == newRoom.title;
});

if (checkCart.length !== 0) {
  alert("Room is already in cart");
  
} else {
  
    setRoomCart([...roomCart,newRoom]);
    alert(room.title +" Reserved successfully"+newRoom._id);
    const res = await axios.put(`/rooms/booked/${newRoom._id}`, {
      dates: alldates }); 
}


}

const removeRoom=(item:any)=>{

 // console.log("before"+item.roomId)
  //console.log(...selectedRooms);
  const newCart = selectedRooms.filter((person:any) => person.roomId !== item.roomId);
  alert("Room removed successfullu");
  setSelectedRooms(newCart);
/*
   const newCart = selectedRooms.filter((room:any) => room.roomId !== roomNumber._id);
                      console.log(newCart);
                       setSelectedRooms(newCart);

                       */

}

  

const Total_price=(roomCart:any)=>{

  const total = selectedRooms.reduce((previousValue:any, currentValue:any) => {
    return previousValue.price + currentValue.price;
  }, 0);

  setTotalP(total);
}

const verifyDays=(days:any)=>{
  if(days==null || days==0)
  {
    setnumberDays(1);
  }
  return days;

}

  const onChangeDate = (dates:any) => {
    const [start, end] = dates;

    
    setStartDate(start);
    
   
/*
    const newData={
      starts:JSON.stringify(start),
      ends:JSON.stringify(end)
    }
*/
  
    
    setEndDate(end);
   
    if(end==null)
    {
      const numberdays=calculateDaysLeft(start,start);
      setnumberDays(numberdays+1);
    }
    if(end!=null)
    {
      const numberdays=(calculateDaysLeft(start,end));
      setnumberDays(numberdays);
      verifyDays(numberdays);
    }
   
   
  };

  const calculateDaysLeft=(startDate:any,endDate:any) =>{
    if (!moment.isMoment(startDate)) startDate = moment(startDate);
    if (!moment.isMoment(endDate)) endDate = moment(endDate);

    return endDate.diff(startDate, "days");
  }

  const daysLeft = calculateDaysLeft(startDate, endDate);


  

  const compare=()=>{
    if(endDate==startDate)
    {
      setEndDate(addDays(startDate,1));
    }
    const numberdays=(calculateDaysLeft(startDate,endDate));
    setnumberDays(numberdays)
    
  }

  const getvalues=()=>{
    docStays.map((stays:any)=>{
      setDisc(stays.photos);
    })
  }

  useEffect(()=>{
    
    const data=docStays;
   // console.log(params.id);
   // console.log("ola"+docStays);
    
    const fetchListings = async () => {
      try {
        
        const response = await axios.get('newPlaces', {
          params: {
            _id:_id
          }
        });
        setStays(response.data)

      } catch (error) {
        console.error('Error fetching listings:', error);
      }};
    
      fetchListings()

    
    getvalues();
    
    //console.log(thisState);
    

    compare();

    //console.log(numberDays);

   // console.log("new dates"+numberDays);
    // addDays(startDate,1))
  //console.log(roomsCart)
  //console.log(selectedRooms);
  
    const total = selectedRooms.reduce((previousValue:any, currentValue:any) => {
      return previousValue + currentValue.price;
    }, 0);
    setTotalP(total);
    //console.log(total);

    //console.log(totalP);
/*
    if(thisState!=null)
    {
      setDetails(thisState);
    }
    /
    if(thisState==null)
    {
      
      setThisState({data:docStays});
      
    }
    */
    //alert("hello master floriano");
   // console.log("master the value is: "+thisState.price);
  },[roomCart,alldates,available,cartState,selectedRooms,startDate,endDate])

  const router = useNavigate();

  function closeModalAmenities() {
    setIsOpenModalAmenities(false);
  }

  function openModalAmenities() {
    setIsOpenModalAmenities(true);
  }

  const handleOpenModalImageGallery = () => {
    router(`${thisPathname}/?modal=PHOTO_TOUR_SCROLLABLE`);
  };

  
  const renderSection1 = () => {
 
    return (
      <div className="listingSection__wrap !space-y-6">
        {/* 1 */}
        <div className="flex justify-between items-center">

        {
          docStays.map((stays:any)=>{
            return( <Badge name={stays.type} />)
          })
        }

          
          <LikeSaveBtns />
        </div>

        {/* 2 */}
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">

        {
          docStays.map((stays:any)=>{
            return(  stays.title)
          })
        }

         
        </h2>

        {/* 3 */}
        <div className="flex items-center space-x-4">
          <StartRating />
          <span>·</span>
          <span>
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1"> 

            {
          docStays.map((stays:any)=>{
            return(  stays.address)
          })
        }
            
            
            </span>
          </span>
        </div>

        {/* 4 */}
        <div className="flex items-center">
          <Avatar hasChecked sizeClass="h-10 w-10" radius="rounded-full" />
          <span className="ml-2.5 text-neutral-500 dark:text-neutral-400">
{//            Hosted by + {localStorage.getItem('startDate')} 
}
            Verified
           
            <span className="text-neutral-900 dark:text-neutral-200 font-medium">
            
            </span>
          </span>
        </div>

        {/* 5 */}
        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

        {/* 6 */}
        <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
          <div className="flex items-center space-x-3 ">
            <i className=" las la-user text-2xl "></i>
            <span className="">
            {
          docStays.map((stays:any)=>{
            return(  stays.maxGuests)
          })
        }
              {//thisState.maxGuests
              } 
              <span className="hidden sm:inline-block">guests</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-bed text-2xl"></i>
            <span className=" ">
            {
          docStays.map((stays:any)=>{
            return(  stays.bedrooms)
          })
        }
              {//thisState.bedrooms
              }
               <span className="hidden sm:inline-block">beds</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-bath text-2xl"></i>
            <span className=" ">
            {
          docStays.map((stays:any)=>{
            return(  stays.bathrooms)
          })
        }
              {//thisState.bathrooms
              } 
              <span className="hidden sm:inline-block">baths</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-door-open text-2xl"></i>
            <span className=" ">
            {
          docStays.map((stays:any)=>{
            return(  stays.bedrooms)
          })
        }
            {//thisState.bedrooms
            } 
            
            <span className="hidden sm:inline-block">bedrooms</span>
            </span>
          </div>
        </div>
      </div>
    );
  };



//Footsticker mobile
  const MobileFooterSticky = () => {
    
    const [showModal, setShowModal] = React.useState(false);
  
    return (
      <div className="block lg:hidden fixed bottom-0 inset-x-0 py-2 sm:py-3 bg-white dark:bg-neutral-800 border-t border-neutral-200 dark:border-neutral-6000 z-40">
        <div className="container flex items-center justify-between">
          
        <>
        
        <div className="flex-grow text-left" onClick={() => setShowModal(true)}>
          
          <span className="block xl:text-lg font-semibold">

          <span>AOA {(totalP*(numberDays))}</span>

          </span>

          <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
          {startDate?.toLocaleDateString("en-US", {
              month: "short",
              day: "2-digit",
            }) || "Add dates"}
            {endDate
              ? " - " +
                endDate?.toLocaleDateString("en-US", {
                  month: "short",
                  day: "2-digit",
                })
              : ""}
          </span>
        </div>


      {showModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <button
                     className="bg-cyan-600 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:bg-cyan-700 shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                     type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Fechar
                  </button>
                <div className="flex items-start justify-center p-5 border-b border-solid border-slate-200 rounded-t">


                
  

               
                  <h3 className="text-2xl font-semibold">
                  
                  <div className="flex-grow text-left">
                    <span className="block xl:text-lg font-semibold">
                      {dates[0].startDate?.toLocaleDateString("en-US", {
                        month: "short",
                        day: "2-digit",
                      }) || "Add dates"}
                      {endDate
                        ? " - " +
                          dates[0].endDate?.toLocaleDateString("en-US", {
                            month: "short",
                            day: "2-digit",
                          })
                        : ""}
                    </span>
                    <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
                      {"Check in - Check out "}
                      
                    </span>
                </div>
                  </h3>

                  
                  
                  </div>
                  
                
                {/*body*/}
                <div className="relative p-6 flex-auto">

                <div className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-neutral-800 p-8">
                 
                <DateRange 
              editableDateInputs={true}
              onChange={(item :any)=> 
               { setStartDate(item.selection.startDate)
                setEndDate(item.selection.endDate)
                setDates([item.selection])
              }
              
              }
              moveRangeOnFirstSelection={false}
              ranges={dates}
            />
               </div>

                </div>
                {/*footer
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                 
                  <button
                    className="bg-teal-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Fechar
                  </button>
                </div>
                */}
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>

    {/*<Link to="/checkout" state={{thisState,dates:{endDate,startDate},numberDays}} >
              
              trocar mobile
          <ButtonPrimary sizeClass="px-5 sm:px-7 py-3 !rounded-2xl">
                Reservars
              </ButtonPrimary>
              </Link> */}

<Link to="/checkout" state={{thisState,docStays,dates:{endDate,startDate},numberDays,selectedRooms,totalP}} >         
              
              <ButtonPrimary>Reservar</ButtonPrimary>
              </Link>

/
          
        </div>
      </div>
    );
  };

  // end mobile sticky

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Stay information</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
          <span>

          {
          docStays.map((stays:any)=>{
            return(  stays.desc)
          })
        }
          {//thisState.stay_info
          }
          </span>
        </div>
      </div>
    );
  };


  //rooms render section
  const renderSection3 = () => {
    return (
      
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Selecionar Quartos </h2>
        
         
         
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {`Selecione Aqui !`}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        <div className="grid grid-cols-1 xl:grid-cols-3 gap-3 text-sm text-neutral-700 dark:text-neutral-300 ">
        { selectedRooms.length!== 0 ?<div><span>
          {selectedRooms.slice(0, 4).map((item:any) => (
            
            <div key={item._id} className="flex items-center space-x-3">
               <div className="flex-shrink-0 w-full sm:w-50 m-2">
              <GallerySlider
               uniqueID={`StayCard_${item._id}`}
               ratioClass="aspect-w-4 aspect-h-3 "
               galleryImgs={item.photos}
               href=""
          /> 
               
              <span className=" "> {item.title}</span>
              <i className={`text-3xl ml-2 las ${Amenities_demos[17].icon}`}>
                </i><br></br>
              <span className=" ">AOA {item.price}</span><br></br>
              <span className=" ">Guests: {item.maxPeople}</span><br></br>
              <span className=" ">Quarto: {item.roomNumber}</span><br></br>
             
             
              <span>
              <button type="button" className="border border-green-500 bg-green-500 text-white rounded-md px-4 py-2 m-2 transition duration-500 ease select-none hover:bg-green-600 focus:outline-none focus:shadow-outline">
        Reservado
      </button>
                </span>

                 <button onClick={()=>removeRoom(item)} type="button" className="border border-red-500 bg-red-500 text-white rounded-md px-4 py-2 m-2 transition duration-500 ease select-none hover:bg-red-600 focus:outline-none focus:shadow-outline">
                Cancelar
                </button>
                <div className="w-full border-b border-neutral-200 dark:border-neutral-700 m-2"></div>
            </div>
            
              </div>
             
          ))}
          </span></div> 
        
        : <div><span>
          
        {doc.slice(0, 4).map((item:any) => (
            
            <div key={item._id} className="flex items-center space-x-3">
               <div className="flex-shrink-0 w-full sm:w-50 m-2">
              <GallerySlider
               uniqueID={`StayCard_${item._id}`}
               ratioClass="aspect-w-4 aspect-h-3 "
               galleryImgs={item.room_photos}
               href=""
              />
               
              <span className=" "> {item.title}</span>
              <i className={`text-3xl ml-2 las ${Amenities_demos[17].icon}`}>
                </i><br></br>
              <span className=" ">AOA {item.price}</span><br></br>
              <span className=" ">Guests: {item.maxPeople}</span><br></br>
             {//test 
             }
               <div className="flex items-center space-x-3 m-2">
              {/* item.roomNumbers.map((roomNumber:any) => {
                

               return (<div className="room">
                  <label>{roomNumber.number} </label>
                 
                  <input
                    type="checkbox"
                    value={[roomNumber._id]}
                    onChange={()=>{
                      
                      //start of handle click function

                      //const checked  = e.target.checked;
                            const room = {
                              hotel_id:item._id,
                              price:item.price,
                              photos:item.room_photos,
                              maxPeople:item.maxPeople,
                              roomId:roomNumber._id,
                              roomNumber:roomNumber.number,
                              title:item.title,
                              
                             
                            }
                            
                            const checkCart = [...selectedRooms].filter((i) => {
                              return i.roomId == roomNumber._id;
                          });

                          if (checkCart.length !== 0) {
                            alert("Room Removed successfully");
                            const newCart = selectedRooms.filter((room:any) => room.roomId !== roomNumber._id);
                            console.log(newCart);
                             setSelectedRooms(newCart);
                         
                          } else {
                            
                            setSelectedRooms([...selectedRooms,room] );
                            alert("Room Added successfully");
                              //setCart({... selectedRooms,...room});
                             // alert(room);
                              //console.log(cartState)
                          }
                        }
                    }

                     //start of handle click function

                      //const checked  = e.target.checked;
                            //const value = e.target.value;

                    disabled={!isAvailable(roomNumber)}
                    
                  />
                </div>
                    )


             }) */}
            </div>
              <span onClick={openModalAmenities}>
              <button onClick={()=>handleClick} disabled={false} type="button" className="border border-ECFEFF-500 bg-cyan-600 text-white rounded-md px-4 py-2 m-2 transition duration-500 ease select-none hover:bg-cyan-500 focus:outline-none focus:shadow-outline">
        Check Availability
      </button>
      

      </span>
     {/* <ButtonPrimary   onClick={handleClick} className="rButton">
          Reserve Now!
        </ButtonPrimary>
*/}
                

            </div>
            
              </div>
             
          ))}
          
          </span></div> 
        
        }


       
          

{/*Amenities_demos.filter((_, i) => i < 12).map((item) => (
            <div key={item.name} className="flex items-center space-x-3">
              <i className={`text-3xl las ${item.icon}`}></i>
              <span className=" ">{item.name}</span>
            </div>
)) */}
        </div>

        {/* ----- */}
        <div className="w-14 border-b border-neutral-200"></div>
        <div>
          <ButtonSecondary onClick={openModalAmenities}>
            More Rooms
          </ButtonSecondary>
        </div>
        {renderMotalAmenities()}
      </div>
    );
  };

  const renderMotalAmenities = () => {
    return (
      <Transition appear show={isOpenModalAmenities} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalAmenities}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
              </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-screen w-full max-w-4xl">
                <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      Rooms
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalAmenities} />
                    </span>
                  </div>

                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                    
                  <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
                  <div><span>
          
          {doc.slice(0, 4).map((item:any) => (
              
              <div key={item._id} className="flex items-center space-x-3">
                 <div className="flex-shrink-0 w-full sm:w-50 m-2">
                <GallerySlider
                 uniqueID={`StayCard_${item._id}`}
                 ratioClass="aspect-w-4 aspect-h-3 "
                 galleryImgs={item.room_photos}
                 href=""
                />
                 
                <span className=" "> {item.title}</span>
                <i className={`text-3xl ml-2 las ${Amenities_demos[17].icon}`}>
                  </i><br></br>
                <span className=" ">AOA {item.price}</span><br></br>
                <span className=" ">Guests: {item.maxPeople}</span><br></br>
               {//test 
               }
                 <div className="flex items-center space-x-3 m-2">
                {item.roomNumbers.map((roomNumber:any) => {
                  
  
                 return (<div className="room">
                    <label>{roomNumber.number} </label>
                   

                   {// Checkbox solutions
                   }
                    
                    {/*
                    <input
                      type="checkbox"
                      className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
                      value={[roomNumber._id]}
                      onChange={()=>{
                        
                        //start of handle click function
  
                        //const checked  = e.target.checked;
                              const room = {
                                hotel_id:item._id,
                                price:item.price,
                                photos:item.room_photos,
                                maxPeople:item.maxPeople,
                                roomId:roomNumber._id,
                                roomNumber:roomNumber.number,
                                title:item.title,
                                
                               
                              }
                              
                              const checkCart = [...selectedRooms].filter((i) => {
                                return i.roomId == roomNumber._id;
                            });
  
                            if (checkCart.length !== 0) {
                              alert("Room Removed successfully");
                              const newCart = selectedRooms.filter((room:any) => room.roomId !== roomNumber._id);
                              console.log(newCart);
                               setSelectedRooms(newCart);
                           
                            } else {
                              
                              setSelectedRooms([...selectedRooms,room] );
                              alert("Room Added successfully");
                                //setCart({... selectedRooms,...room});
                               // alert(room);
                                //console.log(cartState)
                            }
                          }
                      }
  
                       //start of handle click function
  
                        //const checked  = e.target.checked;
                              //const value = e.target.value;
  
                      //disabled={!isAvailable(roomNumber)}
                      checked={!isAvailable(roomNumber)}
                      
                    />

                */}
            

          { isAvailable(roomNumber)?  (<Checked
              key={item.name}
              name={item.name}
               subLabel={roomNumber}
              label={item.name}
              defaultChecked={!isAvailable(roomNumber)}
              disabled={!isAvailable(roomNumber)}
              onChange={()=>{
                        
                //start of handle click function

                //const checked  = e.target.checked;
                      const room = {
                        hotel_id:item._id,
                        price:item.price,
                        photos:item.room_photos,
                        maxPeople:item.maxPeople,
                        roomId:roomNumber._id,
                        roomNumber:roomNumber.number,
                        title:item.title,
                        
                       
                      }
                      
                      const checkCart = [...selectedRooms].filter((i) => {
                        return i.roomId == roomNumber._id;
                    });

                    if (checkCart.length !== 0) {
                      alert("Room Removed successfully");
                      const newCart = selectedRooms.filter((room:any) => room.roomId !== roomNumber._id);
                      console.log(newCart);
                       setSelectedRooms(newCart);
                   
                    } else {
                      
                      setSelectedRooms([...selectedRooms,room] );
                      alert("Room Added successfully");
                        //setCart({... selectedRooms,...room});
                       // alert(room);
                        //console.log(cartState)
                    }
                  }
              }
            />) :
            (<div><CheckBoxs
              key={item.name}
              name={item.name}
              subLabel={roomNumber}
              label={item.name}
              defaultChecked={!isAvailable(roomNumber)}
              disabled={true}
            />
            <span>Ocupado</span>
            </div>)
            
          }



                  </div>
                      )
  
  
               })}
              
              </div>
              <div className="w-full border-b border-neutral-200 dark:border-neutral-700 m-5"></div>
                {/*
                <span onClick={()=>addRoom(item)}>
                <button onClick={()=>handleClick} disabled={false} type="button" className="border border-ECFEFF-500 bg-cyan-600 text-white rounded-md px-4 py-2 m-2 transition duration-500 ease select-none hover:bg-cyan-500 focus:outline-none focus:shadow-outline">
          Add Room
        </button>
        
  
        </span>
        <ButtonPrimary   onClick={handleClick} className="rButton">
            Reserve Now!
          </ButtonPrimary>
  */}
                  
  
              </div>
              
                </div>
               
            ))}
            
            </span></div> 
        </div>

                    { /*Amenities_demos.filter((_, i) => i < 1212).map((item) => (
                      <div
                        key={item.name}
                        className="flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >
                        <i
                          className={`text-4xl text-neutral-6000 las ${item.icon}`}
                        ></i>
                        <span>{item.name}</span>
                      </div>
                    ))*/}
                  </div>

                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const renderSection4 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Room Rates </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Prices may increase on weekends or holidays
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}
        <div className="flow-root">
          <div className="text-sm sm:text-base text-neutral-6000 dark:text-neutral-300 -mb-4">
            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <span>Monday - Thursday</span>
              <span>
              {
          docStays.map((stays:any)=>{
            return(  stays.price)
          })
        }
                {//thisState.price
                }
                </span>
            </div>
            <div className="p-4  flex justify-between items-center space-x-4 rounded-lg">
              <span>Monday - Thursday</span>
              <span>$
                {//thisState.bathrooms
              }</span>
            </div>
            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <span>Friday - Sunday</span>
              <span>$219</span>
            </div>
            <div className="p-4 flex justify-between items-center space-x-4 rounded-lg">
              <span>Rent by month</span>
              <span>-8.34 %</span>
            </div>
            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <span>Minimum number of nights</span>
              <span>1 night</span>
            </div>
            <div className="p-4 flex justify-between items-center space-x-4 rounded-lg">
              <span>Max number of nights</span>
              <span>90 nights</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderSection5 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Host Information</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* host */}
        <div className="flex items-center space-x-4">
          <Avatar
            hasChecked
            hasCheckedClass="w-4 h-4 -top-0.5 right-0.5"
            sizeClass="h-14 w-14"
            radius="rounded-full"
          />
          <div>
            <a className="block text-xl font-medium" href="##">
              Kevin Francis
            </a>
            <div className="mt-1.5 flex items-center text-sm text-neutral-500 dark:text-neutral-400">
              <StartRating />
              <span className="mx-2">·</span>
              <span> 12 places</span>
            </div>
          </div>
        </div>

        {/* desc */}
        <span className="block text-neutral-6000 dark:text-neutral-300">
          Providing lake views, The Symphony 9 Tam Coc in Ninh Binh provides
          accommodation, an outdoor swimming pool, a bar, a shared lounge, a
          garden and barbecue facilities...
        </span>

        {/* info */}
        <div className="block text-neutral-500 dark:text-neutral-400 space-y-2.5">
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <span>Joined in March 2016</span>
          </div>
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
              />
            </svg>
            <span>Response rate - 100%</span>
          </div>
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>

            <span>Fast response - within a few hours</span>
          </div>
        </div>

        {/* == */}
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <ButtonSecondary href="/author">See host profile</ButtonSecondary>
        </div>
      </div>
    );
  };

  const renderSection6 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Reviews (23 reviews)</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* Content */}
        <div className="space-y-5">
          <FiveStartIconForRate iconClass="w-6 h-6" className="space-x-0.5" />
          <div className="relative">
            <Input
              fontClass=""
              sizeClass="h-16 px-4 py-3"
              rounded="rounded-3xl"
              placeholder="Share your thoughts ..."
            />
            <ButtonCircle
              className="absolute right-2 top-1/2 transform -translate-y-1/2"
              size=" w-12 h-12 "
            >
              <ArrowRightIcon className="w-5 h-5" />
            </ButtonCircle>
          </div>
        </div>

        {/* comment */}
        <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <div className="pt-8">
            <ButtonSecondary>View more 20 reviews</ButtonSecondary>
          </div>
        </div>
      </div>
    );
  };

  const renderSection7 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Location</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            San Diego, CA, United States of America (SAN-San Diego Intl.)
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* MAP */}
        <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3 ring-1 ring-black/10 rounded-xl z-0">
          <div className="rounded-xl overflow-hidden z-0">
            <iframe
              title="x"
              width="100%"
              height="100%"
              loading="lazy"
              allowFullScreen
              referrerPolicy="no-referrer-when-downgrade"
              src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAGVJfZMAKYfZ71nzL_v5i3LjTTWnCYwTY&q=Eiffel+Tower,Paris+France"
            ></iframe>
          </div>
        </div>
      </div>
    );
  };

  const renderSection8 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Things to know</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Cancellation policy</h4>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            Refund 50% of the booking value when customers cancel the room
            within 48 hours after successful booking and 14 days before the
            check-in time. <br />
            Then, cancel the room 14 days before the check-in time, get a 50%
            refund of the total amount paid (minus the service fee).
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Check-in time</h4>
          <div className="mt-3 text-neutral-500 dark:text-neutral-400 max-w-md text-sm sm:text-base">
            <div className="flex space-x-10 justify-between p-3 bg-neutral-100 dark:bg-neutral-800 rounded-lg">
              <span>Check-in</span>
              <span>08:00 am - 12:00 am</span>
            </div>
            <div className="flex space-x-10 justify-between p-3">
              <span>Check-out</span>
              <span>02:00 pm - 04:00 pm</span>
            </div>
          </div>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Special Note</h4>
          <div className="prose sm:prose">
            <ul className="mt-3 text-neutral-500 dark:text-neutral-400 space-y-2">
              <li>
                Ban and I will work together to keep the landscape and
                environment green and clean by not littering, not using
                stimulants and respecting people around.
              </li>
              <li>Do not sing karaoke past 11:30</li>
            </ul>
          </div>
        </div>
      </div>
    );
  };

  const renderInput = () => {
    return (
      <>
        <div className="text-neutral-300 dark:text-neutral-400">
          <CalendarIcon className="w-5 h-5 lg:w-7 lg:h-7" />
        </div>
        <div className="flex-grow text-left">
          <span className="block xl:text-lg font-semibold">
            {startDate?.toLocaleDateString("en-US", {
              month: "short",
              day: "2-digit",
            }) || "Add dates"}
            {endDate
              ? " - " +
                endDate?.toLocaleDateString("en-US", {
                  month: "short",
                  day: "2-digit",
                })
              : ""}
          </span>
          <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
            {"Check in - Check out "}
            
          </span>
        </div>
      </>
      
    );
  };


  const renderSidebar = () => {
    return (
      <div className="listingSectionSidebar__wrap shadow-xl">
        {/* PRICE */}
        <div className="flex justify-between">
          <span className="text-3xl font-semibold">
            AOA  {
          docStays.map((stays:any)=>{
            return(stays.cheapestPrice)
          })
        }
            <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
              /Diaria
            </span>
          </span>
          <StartRating />
        </div>

        {/* FORM */}
        <form className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl ">
        <RangeDates/>
            
        {/*  <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
          <GuestsInput className="flex-1" />*/}
          
        </form>

       
<div className="w-full border-b border-neutral-200 dark:border-neutral-700 m-2"></div>
<div className="flex justify-between font-semibold">
            <span>Rooms: {numberDays}</span><br></br>
   </div>
              {selectedRooms.map((item:any) => (
                        
                        <div key={item._id} className="">
                
                
                <div className="flex justify-between text-neutral-6000 dark:text-neutral-200">
                            <span>{item.title}</span>
                          
                            <ButtonSecondary>
                            <span> X </span>
                            </ButtonSecondary>
                          </div>
                        

          
                        
                          </div>
                        
                      ))}


       
<div className="w-full border-b border-neutral-200 dark:border-neutral-700 m-2"></div>

        {/* SUM */}
        <div className="flex flex-col space-y-4">
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>AOA {totalP} x {numberDays} night</span>
            <span>AOA {totalP*(numberDays)}</span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Service charges</span>
            <span>AOA 5000</span>
          </div>
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>Total</span>
            <span>AOA {(totalP*(numberDays)+5000)}</span>
          </div>
        </div>

        {/* SUBMIT */}
        <Link to="/checkout" state={{thisState,docStays,dates:{endDate,startDate},numberDays,selectedRooms,totalP}} >              
        <ButtonPrimary>Reservar</ButtonPrimary>
        </Link>
      </div>
    );
  };


  

  return (
    <div className="nc-ListingStayDetailPage">
      {/*  HEADER */}
      <header className="rounded-md sm:rounded-xl">
        <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
          <div
            className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer "
            onClick={handleOpenModalImageGallery}
          >

            <img
              className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
              src={ 
                
               disc[0]

              }
              alt=""
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
            />
            <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
          </div>
          
          {disc.map((item:any, index:any) => (
            <div
              key={index}
              className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                index >= 2 ? "hidden sm:block" : ""
              }`}
            >
              <div className="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5">
                <img
                  className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
                  src={item}
                  alt="cool"
                  sizes="400px"
                />
              </div>

              {/* OVERLAY */}
              <div
                className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                onClick={handleOpenModalImageGallery}
              />
            </div>
          ))}

          <button
            className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 hover:bg-neutral-200 z-10"
            onClick={handleOpenModalImageGallery}
          >
            <Squares2X2Icon className="w-5 h-5" />
            <span className="ml-2 text-neutral-800 text-sm font-medium">
              Ver todas fotos
            </span>
          </button>
        </div>
      </header>

      {/* MAIN */}
      <main className=" relative z-10 mt-11 flex flex-col lg:flex-row ">
        {/* CONTENT */}
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
          {renderSection1()}
          {renderSection2()}
          {renderSection3()}
          {renderSection4()}
         {// <SectionDateRange />
         }
          {//renderSection5() 
          }
          {//renderSection6()
          }
          {//renderSection7()
          }
          {//renderSection8()
          }
         
        </div>

        {/* SIDEBAR */}
        <div className="hidden lg:block flex-grow mt-14 lg:mt-0">
          <div className="sticky top-28">{renderSidebar()}</div>
        </div>
        
      </main>
      {MobileFooterSticky()}
    </div>
    
  );
};

export default function ListingStayDetailPage() {
  return (
    <DetailPagetLayout>
      <StayDetailPageContainer />
     
    </DetailPagetLayout>
  );
}
