import SectionHero from "components/SectionHero/SectionHero";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import React, { useState } from "react";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionOurFeatures from "components/SectionOurFeatures/SectionOurFeatures";
import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { TaxonomyType2 ,TaxonomyType} from "data/types";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import SectionVideos from "./SectionVideos";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionDowloadApp from "./SectionDowloadApp";
import ButtonPrimary from "shared/Button/ButtonPrimary";
//import { cartState } from "state/cartSore";
//import { useRecoilState } from "recoil";

//categorias de regiao

const DEMO_CATS: TaxonomyType2[] = [
  {
    id: "1",
    href: "/listing-stay",
    country:"Africa do sul",
    name: "Sandton",
    taxonomy: "category",
    count: 10,
    thumbnail:
      "https://joburg.co.za/wp-content/uploads/2020/09/Photo-credit-Nelson-mandela-square-1200x676-1.jpg",
  },
  {
    id: "2",
    href: "/listing-stay",
    country:"Africa do sul",
    name: "Cape Town",
    taxonomy: "category",
    count: 15,
    thumbnail:
      "https://images.unsplash.com/photo-1591742708307-ce49d19450d4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1074&q=80",
  },
  {
    id: "2",
    href: "/listing-stay",
    country:"Namibia",
    name: "Windhoek",
    taxonomy: "category",
    count: 12,
    thumbnail:
      "https://timbuktutravel.imgix.net/2019/02/0-General-windhoek-namibia-timbuktu-travel.jpg?auto=compress%2Cformat&ixlib=php-1.1.0&q=50",
  },
  {
    id: "2",
    href: "/listing-stay",
    country:"Africa do Sul",
    name: "Johannesburg",
    taxonomy: "category",
    count: 12,
    thumbnail:
      "https://images.unsplash.com/photo-1552937075-967cf58b74a4?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8am9oYW5uZXNidXJnfGVufDB8fDB8fHww",
  },
  {
    id: "2",
    href: "/listing-stay",
    country:"Africa do sul",
    name: "Bedfordview",
    taxonomy: "category",
    count: 20,
    thumbnail:
      "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/12/20/e5/df/getlstd-property-photo.jpg?w=500&h=400&s=1",
  },
  {
    id: "2",
    href: "/listing-stay",
    country:"Africa do sul",
    name: "Edenvale",
    taxonomy: "category",
    count: 9,
    thumbnail:
      "https://www.urtrips.com/wp-content/uploads/2022/12/Greenstone-Mall-Johannesburg1.jpg",
  },
  {
    id: "2",
    href: "/listing-stay",
    country:"Africa do sul",
    name: "Bruma",
    taxonomy: "category",
    count: 18,
    thumbnail:
      "https://whatsoninjoburg.com/wp-content/uploads/2016/07/Bruma-Lake-Park.jpg",
  },
  {
    id: "2",
    href: "/listing-stay",
    country:"Africa do sul",
    name: "Primroses",
    taxonomy: "category",
    count: 14,
    thumbnail:
      "https://orionrealestateltd.com/wp-content/uploads/2021/08/Primrose-Mall-6-e1630182194162.jpg",
  },
];

const DEMO_CATS_2: TaxonomyType[] = [
  {
    id: "1",
    href: "/listing-stay",
    country:"Africa do SUl",
    name: "Enjoy the great cold",
    taxonomy: "category",
    count: 13,
    thumbnail:
      "https://images.pexels.com/photos/5764100/pexels-photo-5764100.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
  },
  {
    id: "222",
    href: "/listing-stay",
    country:"Africa do SUl",
    name: "Sleep in a floating way",
    taxonomy: "category",
    count: 20,
    thumbnail:
      "https://images.pexels.com/photos/2869499/pexels-photo-2869499.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "3",
    href: "/listing-stay",
    country:"Africa do SUl",
    name: "In the billionaire's house",
    taxonomy: "category",
    count: 12,
    thumbnail:
      "https://images.pexels.com/photos/7031413/pexels-photo-7031413.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "4",
    href: "/listing-stay",
    country:"Africa do SUl",
    name: "Cool in the deep forest",
    taxonomy: "category",
    count: 10,
    thumbnail:
      "https://images.pexels.com/photos/247532/pexels-photo-247532.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "5",
    href: "/listing-stay",
    country:"Africa do SUl",
    name: "In the billionaire's house",
    taxonomy: "category",
    count: 20,
    thumbnail:
      "https://images.pexels.com/photos/7031413/pexels-photo-7031413.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
];

function PageHome() {
  /*const [add,setAdd]=useRecoilState(cartState);

  const adds=()=>(
    setAdd(add+1)
  );
  */
  return (
    <div className="nc-PageHome relative overflow-hidden">
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />

      <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
        {/* SECTION HERO */}
        <SectionHero className="pt-10 lg:pt-16 lg:pb-16" />
{/*<div>
  <ButtonPrimary onClick={adds}>ADD</ButtonPrimary> {add}
  </div> */}

        {/* SECTION 1 */}
        <SectionSliderNewCategories
          categories={DEMO_CATS}
          uniqueClassName="PageHome_s1"
        />

        {/* SECTION2 */}
        <SectionOurFeatures />

        {/* SECTION */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionGridFeaturePlaces />
        </div>

        {/* SECTION */}
        <SectionHowItWork />

        {/* SECTION 1 */}
        <div className="relative py-16">
          <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
          <SectionSliderNewCategories
            categories={DEMO_CATS_2}
            categoryCardType="card4"
            itemPerRow={4}
            heading="O que mais temos para Oferecer"
            subHeading="Popular places to stay that Barengue recommends for you"
            sliderStyle="style2"
            uniqueClassName="PageHome_s2"
          />
        </div>

        {/* SECTION */}
        <SectionSubscribe2 />

        {/* SECTION 
        <div className="relative py-16">
          <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
          <SectionGridAuthorBox />
        </div>
        */}

        {/* SECTION */}
        <SectionGridCategoryBox />

        {/* SECTION */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionBecomeAnAuthor />
        </div>

        {/* SECTION 1
        <SectionSliderNewCategories
          heading="Explore by types of stays"
          subHeading="Explore houses based on 10 types of stays"
          categoryCardType="card5"
          itemPerRow={5}
          uniqueClassName="PageHome_s3"
        />
         */}

        {/* SECTION 
        <SectionVideos />
        */}
        {/* SECTION */}
        <SectionDowloadApp />

        {/* SECTION */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageHome_" />
        </div>
      </div>
    </div>
  );
}

export default PageHome;
