import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
//import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
//import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import React, { Fragment,FC, useEffect, useState } from "react";
//import SectionGridFilterCard from "./SectionGridFilterCard";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import pic from "../../images/hero-right4.png"
import Heading2 from "components/Heading/Heading2";
import TabFilters from "./TabFilters";
import StayCard from "components/StayCard/StayCard";
import Pagination from "shared/Pagination/Pagination";
import useFetch from "hooks/useFecth";
import { rangeStays,stayAmenities,stayTypes,stayType,locationStays,roomStays } from "state/cartSore";
import { useRecoilValue,useRecoilState } from "recoil";
import LoadingSpinner from "shared/LoadingSpinner";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Dialog, Popover, Transition } from "@headlessui/react";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";

import ButtonThird from "shared/Button/ButtonThird";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Checkbox from "shared/Checkbox/Checkbox";
import Slider from "rc-slider";
import axios from "axios";
//import { typeOfPaces } from "data/types";

export interface ListingStayPageProps {
  className?: string;
  
}



const ListingStayPage: FC<ListingStayPageProps> = ({ className = "" }) => {
  const [location,setLocation]=useState(useLocation().state);

  const [name,setName]=useState('');
  const [country,setCountry]=useState('');
  const[wifi,setWifi]=useState(undefined);
  const[breakfast,setBreakfast]=useState(false);
  const mini=useRecoilValue(rangeStays)
  
  const [max, setMax] = useState(undefined);
  const[zap,setZap]=useState(undefined);
  //const link= `/hotels?city=${destination}&min=${min || 0 }&max=${max || 999}&zap=${zap=true}&wifi=${wifi=true}`;
  const reset= `/hotels?city=${name}&min=${mini[0] || 0 }&max=${mini[1] || 999}`;
  const{doc,loading,reFetch}=useFetch(reset);
  const amenities=useRecoilValue(stayAmenities);
  //const types=useRecoilValue(stayTypes);
  const [stays,setStays]=useState<any>([]);
  const [types,setTypes]=useRecoilState(stayType);
  const city=useRecoilValue(locationStays);
  const bedrooms=useRecoilValue(roomStays)
  



  

  useEffect(()=>{
    
    

    const fetchListings = async () => {
      try {
        
        const response = await axios.get('newPlaces', {
          params: {
            city:city,
            amenities: amenities,
            type:types,
            bedrooms:bedrooms,
            priceRange:mini
        
          }
        });
        setStays(response.data);
        console.log(response.data)

      } catch (error) {
        console.error('Error fetching listings:', error);
      }};
    
      fetchListings()


    console.log(amenities);
    console.log(types)

    if(location==null ||location.name==null|| location.country==null )
    {
      setName("Mundo")
      setCountry("Todos os Paises")
      setLocation([{name:"kim",coutry:"Mundo"}])
      console.log(location);
     
    }
    else{
    setName(location.name);
    setCountry(location.country);
    }
    //console.log(name,location,mini);
    
  },[amenities,types,mini,city,bedrooms])

  


  return (
    <div
      className={`nc-ListingStayPage relative overflow-hidden ${className}`}
      data-nc-id="ListingStayPage"
    >
      <Helmet>
        <title>Barengue || Bookings, Hotels, stays</title>
      </Helmet>
      <BgGlassmorphism />
     {//name} +{country
    }
      <div className="container relative overflow-hidden">
        {/* SECTION HERO */}
        <SectionHeroArchivePage
          currentPage="Estadias"
          currentTab="Estadias"
          className="pt-10 pb-24 lg:pb-28 lg:pt-16 "
          rightImage ={pic}
          name="Where do you want to go?"
          country="Estadias"
        />

        {/* SECTION 
        <SectionGridFilterCard className="pb-24 lg:pb-28" />

        */}
       
      
        
        <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      <Heading2 />

      <div className="mb-8 lg:mb-11">
     

        <TabFilters />
       
      </div>

{/*stays.map((stay:any,id:any) => (
          <StayCard key={id} data={stay} />
        )) */}
      {loading ? (
       <ButtonPrimary loading={loading}>Loading</ButtonPrimary>
      
      ) : 
      <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">

        { stays.length>0 ?  

            stays.map((stay:any,id:any) => (
              <StayCard key={id} data={stay} />
            ))
        

         :
         <ButtonPrimary loading={true}>Nenhum item encontrado na sua procura</ButtonPrimary>
        }

      </div>
          }       

      <div className="flex mt-16 justify-center items-center">
        <Pagination />
      </div>
    </div>

    



        {/* SECTION 1 */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionSliderNewCategories
            heading="Explore by types of stays"
            subHeading="Explore houses based on 10 types of stays"
            categoryCardType="card5"
            itemPerRow={5}
            sliderStyle="style2"
            uniqueClassName="ListingStayMapPage"
          />
        </div>

        {/* SECTION 
        <SectionSubscribe2 className="py-24 lg:py-28" />
        */}

        {/* SECTION */}
        <div className="relative py-16 mb-24 lg:mb-28">
            {/* 
          <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
      

  <SectionGridAuthorBox /> */}
        </div>
      </div>
    </div>
  );

  

};


export default ListingStayPage;
