
import { useEffect, useState } from "react";
import axios from "axios";
import { AnyARecord } from "dns";

const useFetchStay = (url:any,types:any[],rangePrices:any[]) => {
  const [docStays, setDoc] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorStays, setError] = useState(false);


  


  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await axios.get('/newAttractions', {
          params: {
            type:types,
            priceRange:rangePrices,
            //name:name,
          }
        });
        setDoc(res.data);
      } catch (err:any) {
        setError(err);
        alert("No internet connection")
      }
      setLoading(false);
    };
    fetchData();
  }, [url]);
  

  const reFetchStays = async () => {
    setLoading(true);
    try {
      const res = await axios.get('/newAttractions', {
        params: {
          type:types,
          priceRange:rangePrices,
          //name:name,
        }
      });
      setDoc(res.data);
    } catch (err:any) {
      setError(err);
    }
    setLoading(false);
  };

  return { docStays, loading, errorStays, reFetchStays };
};

export default useFetchStay;
