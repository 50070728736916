import React, { FC, useEffect, useState } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import axios from "axios";
import { users,isLoggedIn } from "state/cartSore";
import { useRecoilState } from "recoil";
import { Navigate ,useNavigate} from "react-router-dom";
import Logo from "shared/Logo/Logo";

export interface PageLoginProps {
  className?: string;
}

const loginSocials = [
  {
    name: "Continue with Facebook",
    href: "#",
    icon: facebookSvg,
  },
  {
    name: "Continue with Twitter",
    href: "#",
    icon: twitterSvg,
  },
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
  },
];

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {


    const navigate=useNavigate();
  const[userData,setUser]=useRecoilState<any>(users);
  const[isLogged,setIsLogged]=useRecoilState(isLoggedIn);
  const[email,setEmail]=useState("");
  const[username,setUsername]=useState("");
  const [password,setPassword]=useState("");
  const [resdata,setRes]=useState<any>([])
  const [loading,setLoading]=useState(false)
  const login=async (ev:any)=>{

    ev.preventDefault();
    try{
      setLoading(true)
      const res=await axios.post('/auth/login',{
        
        username,
        password,} );
  
      const data={
        name:res.data.details.username,
        email:res.data.details.email,
        phone:res.data.details.phone,
      }
      localStorage.setItem("user",JSON.stringify(data));
      localStorage.setItem("isLoggedIn", JSON.stringify(true));
      setIsLogged(true);
      setUser(data)
      navigate("/")
      alert("Login com sucesso.")
      setLoading(false)
    }
    catch(e){
      console.log(e);
      alert("Erro ao fazer o Login, por favor tente mais tarde")
      setLoading(false)
    }
  }


  useEffect(()=>{
   isLogged==true &&(
    navigate("/")
  )
  },[])



  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Login || Booking React Template</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
      
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Login
        </h2>
       
        <div className="max-w-md mx-auto space-y-6">
         {/* <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </a>
            ))}
          </div>
          {/* OR }
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
           
          </div> */}
          {/* FORM 
          //<Logo className="items-center" /> */}
          <form className="grid grid-cols-1 gap-6" action="#" method="post" onSubmit={login}>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email              </span>
              <Input
                type="text"
                value={username}
                onChange={ev=>setUsername(ev.target.value)}
                placeholder="example@example.com"
                className="mt-1"
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
               
              </span>
              <Input 
              type="password" 
              className="mt-1"
              onChange={ev=>setPassword(ev.target.value)}
               />
            </label>
            <ButtonPrimary  loading={loading} type="submit">Continue</ButtonPrimary>
          </form>
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
          <Link to="/forgot-pass" className="text-sm m-1">
                  Forgot password?
                </Link> 
          {/* ==== */}
         
            <Link to="/signup" className="text-cyan-800  text-sm m-1">Create an account</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
