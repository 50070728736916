import React, { FC, useEffect, useState } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";
import StartRating from "components/StartRating/StartRating";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";
import useFetchStays from "hooks/useFecthStays";

export interface StayCardProps {
  className?: string;
  data?: any;
  size?: "default" | "small";
}

const DEMO_DATA = DEMO_STAY_LISTINGS[0];

const StayCard: FC<StayCardProps> = ({
  size = "default",
  className = "",
  data=DEMO_DATA }) => {

    const [id,setId]=useState("5");
    const value=`/listing-stay-details/${data._id}`;
    const searchStays=`/hotels?_id=${data._id}`;
    const {docStays,loadingStays,reFetchStays}=useFetchStays(searchStays);
  
  useEffect(()=>{

    
   //console.log("im here master"+docStays);

    //console.log(DEMO_DATA);
  })

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        
        <GallerySlider
          uniqueID={`StayCard_${DEMO_DATA.id}`}
          ratioClass="aspect-w-4 aspect-h-3 "
          galleryImgs={data.photos}
          href={DEMO_DATA.href}
        />
        <BtnLikeIcon isLiked={DEMO_DATA.like} className="absolute right-3 top-3 z-[1]" />
        {DEMO_DATA.saleOff && <SaleOffBadge className="absolute left-3 top-3" />}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
        <div className="space-y-2">
          <span className="text-sm text-neutral-500 dark:text-neutral-400">
            {data.name} · {data.type} Quartos
          </span>
          <div className="flex items-center space-x-2">
            {DEMO_DATA.isAds && <Badge name="ADS" color="green" />}
            <h2
              className={` font-medium capitalize ${
                size === "default" ? "text-lg" : "text-base"
              }`}
            >
              <span className="line-clamp-1">{data.name}</span>
            </h2>
          </div>
          <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
            {size === "default" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            )}
            <span className="">{data.city}</span>
          </div>
        </div>
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
        <div className="flex justify-between items-center">
          <span className="text-base font-semibold">
            {data.cheapestPrice}
            {` `}
            {size === "default" && (
              <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                /night
              </span>
            )}
          </span>
          {!!DEMO_DATA.reviewStart && (
            <StartRating reviewCount={DEMO_DATA.reviewCount} point={DEMO_DATA.reviewStart} />
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="StayCard"
    >
       <Link to={value} state={{...DEMO_DATA,data}}>
      {renderSliderGallery()}
      </Link>
      
      <Link to={value} state={{...DEMO_DATA,data}}>
        {renderContent()} 
        </Link>
    </div>
  );
};

export default StayCard;
