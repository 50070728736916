import React, { FC, useEffect } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { useRecoilState } from "recoil";
import { isLoggedIn,users } from "state/cartSore";
import { useNavigate } from "react-router-dom";

export interface PageSignUpProps {
  className?: string;
}



const loginSocials = [
  {
    name: "Continue with Facebook",
    href: "#",
    icon: facebookSvg,
  },
  {
    name: "Continue with Twitter",
    href: "#",
    icon: twitterSvg,
  },
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
  },
];


const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {
  const navigate=useNavigate();
  const[userData,setUser]=useRecoilState<any>(users);
  const[isLogged,setIsLogged]=useRecoilState(isLoggedIn);
  
const [username,setName]=useState("");
const[email,setEmail]=useState("");
const[password,setPwd]=useState("");
const[phone,setPhone]=useState("");
const [data,setData]=useState<any>([])
const [loading,setLoading]=useState(false)



const login=async ()=>{

  //ev.preventDefault();
  try{
    setLoading(true)
    const res=await axios.post('/auth/login',{
      
      username,
      password,} );

    const data={
      name:res.data.details.username,
      email:res.data.details.email,
      phone:res.data.details.phone,
    }
    localStorage.setItem("user",JSON.stringify(data));
    localStorage.setItem("isLoggedIn", JSON.stringify(true));
    setIsLogged(true);
    setUser(data)
    navigate("/")
    alert("Login com sucesso.")
    setLoading(false)
  }
  catch(e){
    console.log(e);
    alert("Erro ao fazer o Login, por favor tente mais tarde")
    setLoading(false)
  }
}


//register users


  async function register(ev:any){

  ev.preventDefault();
  try{
    setLoading(true)
  await axios.post('/auth/register',{
    username,
    email,
    password,
    phone,
  });
  alert("conta criada com sucesso.")
  setLoading(false)
  login();
  navigate("/login")
//Login
  {/*try{
    const res=await axios.post('/auth/login',{
      
      username,
      password,} );

    alert("Login com sucesso.")
    setData(users);
    localStorage.setItem("user",res.data.details);
    localStorage.setItem("isLoggedIn", JSON.stringify(true));
    setIsLogged(true);
    setUser({
      username,
      email}
    )

  }
  catch(e){
    console.log(e);
    alert("Erro ao fazer o Login, por favor tente mais tarde")
  }*/}

  //end LOGIN

}
catch(e){
  console.log(e);
  alert("Erro ao fazer o registro, por favor tente mais tarde")
}
}
useEffect(()=>{
  console.log("Data"+data);
  console.log("Logged In: "+isLogged);
},[])
  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Sign up || Barengue Bookings</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Signup
        </h2>
        <div className="max-w-md mx-auto space-y-6 ">
          <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </a>
            ))}
          </div>
          {/* OR */}
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" action="#" method="post" onSubmit={register}>
          <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Nome
              </span>
              <Input
              required
              value={username}
              onChange={ev=>setName(ev.target.value)}
                type="text"
                placeholder="Agostinho Neto"
                className="mt-1"
              />
            </label>

            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
              <Input
              required
                value={email}
                onChange={ev=>setEmail(ev.target.value)}
                type="email"
                placeholder="example@example.com"
                className="mt-1"
              />
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
               Telefone
              </span>
              <Input
              required
                value={phone}
                onChange={ev=>setPhone(ev.target.value)}
                type="number"
                placeholder="923 00 00 00"
                className="mt-1 border-1"
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
              </span>

              <Input 
              required
              value={password}
              onChange={ev=>setPwd(ev.target.value)}
              type="password" className="mt-1" />
            </label>
            {/*
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Confirm Password
              </span>
              <Input type="password" className="mt-1" />
            </label>
            */}
            <ButtonPrimary loading={loading} type="submit">Continue</ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Already have an account? {` `}
            <Link to="/login" className="text-cyan-800 ">Sign in</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageSignUp;
